// Models
import { routePermissionType } from "../Models/types";
// Models

// Pages
import AccessibleTenants from "../Pages/AccessibleTenants/AccessibleTenants";
import AddTenant from "../Pages/AccessibleTenants/AddTenant/AddTenant";
import AddNewForm from "../Pages/Forms/AddNewForm/AddNewForm";
import EditFormsByProcessKey from "../Pages/Forms/EditFormsByProcessKey/EditFormsByProcessKey";
import ShowFormsByProcessKey from "../Pages/Forms/ShowFormsByProcessKey/ShowFormsByProcessKey";
import ShowSelectedForm from "../Pages/Forms/ShowSelectedForm/ShowSelectedForm";
import Profile from "../Pages/Profile/Profile";
import AddUser from "../Pages/Users/AddUser/AddUser";
import SingleUser from "../Pages/Users/SingleUser/SingleUser";
import Users from "../Pages/Users/Users";
import Process from "../Pages/Processes/Processes";
import ShowSelectedProcessByProcessKey from "../Pages/Processes/ShowSelectedProcessByProcessKey/ShowSelectedProcessByProcessId";
import ShowTasksByProcessKey from "../Pages/Processes/ShowTasksByProcessKey/ShowTasksByProcessId";
import ViewRunningInstances from "../Pages/Processes/ViewRunningInstances/ViewRunningInstances";
import EditProcessByProcessKey from "../Pages/Processes/EditProcessByProcessKey/EditProcessByProcessKey";
import AddNewProcess from "../Pages/Processes/AddNewProcess/AddNewProcess";
import FastCreate from "../Pages/Processes/AddNewProcess/FastCreate/FastCreate";
import SubmitStartingForm from "../Pages/Processes/SubmitStartingForm/SubmitStartingForm";
import ShowInstanceDataByInstanceId from "../Pages/Processes/ShowInstanceDataByInstanceId/ShowInstanceDataByInstanceId";
import NewReport from "../Pages/Reports/NewReport/NewReport";
import Reports from "../Pages/Reports/Reports";
import Groups from "../Pages/Groups/Groups";
import AddGroup from "../Pages/Groups/AddGroup/AddGroup";
import AddIndirectMembership from "../Pages/Groups/AddIndirectMembership/AddIndirectMembership";
import AddGroupOnGroup from "../Pages/Groups/AddGroupOnGroup/AddGroupOnGroup";
import EditPublicGroup from "../Pages/Groups/EditPublicGroup/EditPublicGroup";
import Tasks from "../Pages/Tasks/Tasks";
import TaskDetailFromTasks from "../Pages/Tasks/TaskDetailFromTasks/TaskDetailFromTasks";
import TaskFormAndSubmitTaskFormByTasks from "../Pages/Tasks/TaskFormAndSubmitTaskFormByTasks/TaskFormAndSubmitTaskFormByTasks";
import ServerManagement from "../Pages/ServerManagement/ServerManagement";
import NewServer from "../Pages/ServerManagement/NewServer/NewServer";
import UserIdentities from "../Pages/UserIdentities/UserIdentities";
import NewIdentity from "../Pages/UserIdentities/NewIdentity/NewIdentity";
import ShowSelectedIdentity from "../Pages/UserIdentities/ShowSelectedIdentity/ShowSelectedIdentity";
import CreateSubAttributeForSingleIdentityAttribute from "../Pages/UserIdentities/CreateSubAttributeForSingleIdentityAttribute/CreateSubAttributeForSingleIdentityAttribute";
import EditSingleAttribute from "../Pages/UserIdentities/EditSingleAttribute/EditSingleAttribute";
import AddOptionToSingleAttribute from "../Pages/UserIdentities/AddOptionToSingleAttribute/AddOptionToSingleAttribute";
import EditSingleIdentityAttribute from "../Pages/UserIdentities/EditSingleIdentityAttribute/EditSingleIdentityAttribute";
import CallOfRegistration from "../Pages/CallOfRegistration/CallOfRegistration";
import SingleCallOfRegistrationAttributes from "../Pages/CallOfRegistration/SingleCallOfRegistrationAttributes/SingleCallOfRegistrationAttributes";
import SingleCallOfRegistrationDetails from "../Pages/CallOfRegistration/SingleCallOfRegistrationDetails/SingleCallOfRegistrationDetails";
import ShowAllRequestsForSingleCAllOfRegistration from "../Pages/CallOfRegistration/ShowAllRequestsForSingleCAllOfRegistration/ShowAllRequestsForSingleCAllOfRegistration";
import AddNewSingleCallOfRegistration from "../Pages/CallOfRegistration/AddNewSingleCallOfRegistration/AddNewSingleCallOfRegistration";
import EditSingleCallOfRegistration from "../Pages/CallOfRegistration/EditSingleCallOfRegistration/EditSingleCallOfRegistration";
import CheckCurrStateOfRegistration from "../Pages/CallOfRegistration/CheckCurrStateOfRegistration/CheckCurrStateOfRegistration";
import EditSingleCallOfRegistrationRequest from "../Pages/CallOfRegistration/EditSingleCallOfRegistrationRequest/EditSingleCallOfRegistrationRequest";
import Posts from "../Pages/Posts/Posts";
import ShowSinglePost from "../Pages/Posts/ShowSinglePost/ShowSinglePost";
import AddNewPost from "../Pages/Posts/AddNewPost/AddNewPost";
import EditSinglePost from "../Pages/Posts/EditSinglePost/EditSinglePost";
import SecurityEvents from "../Pages/SecurityEvents/SecurityEvents";
import FormSamples from "../Pages/FormSamples/FormSamples";
import AddNewFormSample from "../Pages/FormSamples/AddNewFormSample/AddNewFormSample";
import ViewSingleFormSample from "../Pages/FormSamples/ViewSingleFormSample/ViewSingleFormSample";
import EditSingleFormSample from "../Pages/FormSamples/EditSingleFormSample/EditSingleFormSample";
import ShowFormOfAllocatedProcessAndStartIt from "../Pages/AllocatedProcesses/ShowFormOfAllocatedProcessAndStartIt/ShowFormOfAllocatedProcessAndStartIt";
import AllocatedProcesses from "../Pages/AllocatedProcesses/AllocatedProcesses";
import HelpCenter from "../Pages/HelpCenter/HelpCenter";
import Home from "../Pages/Home/Home";
// Pages

export const routesAndPermissions: routePermissionType[] = [
  /****
   *
   *
   *
   *
   *
   *
   */
  // HOME
  {
    route: "",
    neededPermissions: ["Active_Users"],
    Element: Home,
  },
  // HOME
  /****
   *
   *
   *
   *
   *
   *
   */
  // TENANTS
  {
    route: "/accessible-tenants",
    neededPermissions: ["Tenant_Full_Manager", "Tenant_Basic_Manager"],
    Element: AccessibleTenants,
  },
  {
    route: "/accessible-tenants/new-tenant",
    neededPermissions: [],
    Element: AddTenant,
  },
  // TENANTS
  /****
   *
   *
   *
   *
   *
   *
   */
  //   USERS
  {
    route: "/users",
    neededPermissions: [
      "Full_Manager",
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "User_Editor",
      "User_Information_Reader",
    ],
    Element: Users,
  },
  {
    route: "/users/single-user/:userId",
    neededPermissions: [
      "Full_Manager",
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "User_Editor",
    ],
    Element: SingleUser,
  },
  {
    route: "/users/add-user",
    neededPermissions: [
      "Full_Manager",
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "User_Editor",
    ],
    Element: AddUser,
  },
  //   USERS
  /****
   *
   *
   *
   *
   *
   *
   */
  // PROFILE
  {
    route: "/profile",
    neededPermissions: ["Active_Users"],
    Element: Profile,
  },
  // PROFILE
  /****
   *
   *
   *
   *
   *
   *
   */
  // FORMS
  {
    route: "/forms/show-form-by-process-key/:processKey/:persianProcessName",
    neededPermissions: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "Process_Form_Editor",
      "Process_Full_Manager",
    ],
    Element: ShowFormsByProcessKey,
  },
  {
    route:
      "/forms/show-form-by-process-key/new-form/:processKey/:persianProcessName",
    neededPermissions: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "Process_Form_Editor",
      "Process_Full_Manager",
    ],
    Element: AddNewForm,
  },
  {
    route:
      "/forms/show-form-by-process-key/new-form/:processKey/:persianProcessName/:tenantId",
    neededPermissions: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "Process_Form_Editor",
      "Process_Full_Manager",
    ],
    Element: AddNewForm,
  },
  {
    route: "/forms/show-form-by-process-key/show-selected-form",
    neededPermissions: ["Active_Users"],
    Element: ShowSelectedForm,
  },
  {
    route:
      "/forms/show-form-by-process-key/edit-selected-form/:processKey/:formId/:persianProcessName",
    neededPermissions: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "Form_Sample_Manager",
    ],
    Element: EditFormsByProcessKey,
  },
  // FORMS
  /****
   *
   *
   *
   *
   *
   *
   */
  //   PROCESSES
  {
    route: "/processes",
    neededPermissions: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "Process_Full_Manager",
      "Process_Staff_Editor",
      "Process_Editor",
      "Process_Starter",
      "Process_Schema_Analyzer",
      "Process_Form_Editor",
    ],
    Element: Process,
  },
  {
    route:
      "/processes/show-process-by-processKey/:processKey/:persianProcessKey/:tanentId",
    neededPermissions: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "Process_Full_Manager",
      "Running_Process_Manager",
      "Process_Schema_Analyzer",
    ],
    Element: ShowSelectedProcessByProcessKey,
  },
  {
    route:
      "/processes/show-process-by-processKey/:processKey/:persianProcessKey",
    neededPermissions: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "Process_Full_Manager",
      "Running_Process_Manager",
      "Process_Schema_Analyzer",
    ],
    Element: ShowSelectedProcessByProcessKey,
  },
  {
    route:
      "/processes/show-tasks-by-processKey/:processKey/:persianProcessName",
    neededPermissions: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "Process_Full_Manager",
      "Running_Process_Task_Manager",
    ],
    Element: ShowTasksByProcessKey,
  },
  {
    route: "/processes/show-tasks-by-processKey/:processKey/",
    neededPermissions: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "Process_Full_Manager",
      "Running_Process_Task_Manager",
    ],
    Element: ShowTasksByProcessKey,
  },
  {
    route: "/processes/view-running-instances/:processKey/:presianProcessName",
    neededPermissions: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "Process_Full_Manager",
      "Running_Process_Manager",
    ],
    Element: ViewRunningInstances,
  },
  {
    route:
      "/processes/view-running-instances/:processKey/:presianProcessName/:tenantId",
    neededPermissions: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "Process_Full_Manager",
      "Running_Process_Manager",
    ],
    Element: ViewRunningInstances,
  },
  {
    route: "/processes/edit-process-by-processKey/:processFileName/:processKey",
    neededPermissions: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "Process_Full_Manager",
      "Process_Editor",
    ],
    Element: EditProcessByProcessKey,
  },
  {
    route:
      "/processes/edit-process-by-processKey/:processFileName/:processKey/:tenantId",
    neededPermissions: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "Process_Full_Manager",
      "Process_Editor",
    ],
    Element: EditProcessByProcessKey,
  },
  {
    route: "/processes/new-process",
    neededPermissions: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "Process_Full_Manager",
      "Process_Editor",
    ],
    Element: AddNewProcess,
  },
  {
    route: "/processes/new-process/fast-create",
    neededPermissions: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "Process_Full_Manager",
      "Process_Editor",
    ],
    Element: FastCreate,
  },
  {
    route:
      "/processes/complete-starting-form-by-processKey/:processKey/:persianProcessName",
    neededPermissions: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "Process_Full_Manager",
      "Running_Process_Task_Manager",
    ],
    Element: SubmitStartingForm,
  },
  {
    route:
      "/processes/complete-starting-form-by-processKey/:processKey/:persianProcessName/:tenantId",
    neededPermissions: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "Process_Full_Manager",
      "Running_Process_Task_Manager",
    ],
    Element: SubmitStartingForm,
  },
  {
    route: "/processes/show-instance-data-by-instance-id/:instanceId",
    neededPermissions: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "Process_Full_Manager",
      "Running_Process_Manager",
      "Process_Schema_Analyzer",
    ],
    Element: ShowInstanceDataByInstanceId,
  },
  //   PROCESSES
  /****
   *
   *
   *
   *
   *
   *
   */
  //   REPORTS
  {
    route: "/processes/reports/:processKey/:persianProcessName",
    neededPermissions: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "Process_Full_Manager",
    ],
    Element: Reports,
  },
  {
    route: "/processes/reports/new-report/:processKey/:persianProcessName",
    neededPermissions: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "Process_Full_Manager",
    ],
    Element: NewReport,
  },
  //   REPORTS
  /****
   *
   *
   *
   *
   *
   *
   */
  //   GROUPS
  {
    route: "/groups",
    neededPermissions: [
      // "Tenant_Full_Manager",
      // "Tenant_Basic_Manager",
      // "All_Group_Manager",
      // "Public_Group_Manager",
      "Active_Users",
    ],
    Element: Groups,
  },
  {
    route: "/groups/new-group",
    neededPermissions: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "All_Group_Manager",
      "Public_Group_Manager",
    ],
    Element: AddGroup,
  },
  {
    route: "/groups/new-group/indirect-membership",
    neededPermissions: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "All_Group_Manager",
      "Public_Group_Manager",
    ],
    Element: AddIndirectMembership,
  },
  {
    route: "/groups/new-group/group-on-group",
    neededPermissions: ["Tenant_Full_Manager", "Tenant_Basic_Manager"],
    alternativePermission: (allGroups) =>
      allGroups.includes("Full_Manager") &&
      allGroups.includes("All_Group_Manager"),
    Element: AddGroupOnGroup,
  },
  {
    route: "/groups/edit-public-groups/:groupId/:groupPersianName",
    neededPermissions: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "All_Group_Manager",
      "Public_Group_Manager",
    ],
    Element: EditPublicGroup,
  },
  //   GROUPS
  /****
   *
   *
   *
   *
   *
   *
   */
  //   TASKS
  {
    route: "/tasks",
    neededPermissions: ["Active_Users"],
    Element: Tasks,
  },
  {
    route: "/tasks/task-detail/:taskId",
    neededPermissions: ["Active_Users"],
    Element: TaskDetailFromTasks,
  },
  {
    route: "/tasks/submit-task-form/:taskId",
    neededPermissions: ["Active_Users"],
    Element: TaskFormAndSubmitTaskFormByTasks,
  },
  //   TASKS
  /****
   *
   *
   *
   *
   *
   *
   */
  //   SERVER_MANAGEMENT
  {
    route: "/servers",
    neededPermissions: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "Settings_Editor",
    ],
    Element: ServerManagement,
  },
  {
    route: "/servers/new-server",
    neededPermissions: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "Settings_Editor",
    ],
    Element: NewServer,
  },
  //   SERVER_MANAGEMENT
  /****
   *
   *
   *
   *
   *
   *
   */
  //   USER_IDENTITIES
  {
    route: "/user-identities",
    neededPermissions: ["Active_Users"],
    Element: UserIdentities,
  },
  {
    route: "/user-identities/new-identity",
    neededPermissions: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "Identity_Attributes_Manager",
    ],
    Element: NewIdentity,
  },
  {
    route: "/user-identities/show-selected-identity/:identityId/:persianName",
    neededPermissions: ["Active_Users"],
    Element: ShowSelectedIdentity,
  },
  {
    route:
      "/user-identities/create-sub-attribute-for-single-userIdentity-by-id/",
    neededPermissions: ["Active_Users"],
    Element: CreateSubAttributeForSingleIdentityAttribute,
  },
  {
    route: "/user-identities/edit-single-attribute/:identityId/:persianName",
    neededPermissions: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "Identity_Attributes_Manager",
    ],
    Element: EditSingleAttribute,
  },
  {
    route:
      "/user-identities/add-option-to-single-attribute/:identityId/:persianName",
    neededPermissions: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "Identity_Attributes_Manager",
    ],
    Element: AddOptionToSingleAttribute,
  },
  {
    route:
      "/user-identities/add-option-to-single-attribute/edit-selected-attribute/:attributeId/:optionId/:persianName",
    neededPermissions: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "Identity_Attributes_Manager",
    ],
    Element: EditSingleIdentityAttribute,
  },
  //   USER_IDENTITIES
  /****
   *
   *
   *
   *
   *
   *
   */
  //   CALL_OF_REGISTRATION
  {
    route: "/call-of-registration",
    neededPermissions: ["Active_Users"],
    Element: CallOfRegistration,
  },
  {
    route: "/call-of-registration/identities/details/:callOfRegistrationId",
    neededPermissions: ["Active_Users"],
    Element: SingleCallOfRegistrationAttributes,
  },
  {
    route:
      "/call-of-registration/single-callOf-registration/details/:callOfRegistrationId",
    neededPermissions: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "Call_Of_Registration_Manager",
    ],
    Element: SingleCallOfRegistrationDetails,
  },
  {
    route: "/call-of-registration/requests/details/:callOfRegistrationId",
    neededPermissions: ["Tenant_Full_Manager", "Tenant_Basic_Manager"],
    alternativePermission: (allGroups) =>
      allGroups.includes("Call_Of_Registration_Manager") && "Full_Manager"
        ? true
        : false ||
          (allGroups.includes("Call_Of_Registration_Manager") && "User_Editor")
        ? true
        : false,
    Element: ShowAllRequestsForSingleCAllOfRegistration,
  },
  {
    route: "/call-of-registration/add-new",
    neededPermissions: ["Tenant_Full_Manager", "Tenant_Basic_Manager"],
    alternativePermission: (allGroups) =>
      allGroups.includes("Call_Of_Registration_Manager") && "Full_Manager"
        ? true
        : false ||
          (allGroups.includes("Call_Of_Registration_Manager") && "User_Editor")
        ? true
        : false,
    Element: AddNewSingleCallOfRegistration,
  },
  {
    route: "/call-of-registration/edit/edit-single/:callOfRegistrationId",
    neededPermissions: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "CALL_OF_REGISTRATIONS_MANAGER",
    ],
    alternativePermission: (allGroups) =>
      allGroups.includes("User_Editor") || allGroups.includes("Full_Manager"),
    Element: EditSingleCallOfRegistration,
  },
  {
    route: "/call-of-registration/check-current-state-of-registration",
    neededPermissions: ["Active_Users"],
    Element: CheckCurrStateOfRegistration,
  },
  {
    route: "/call-of-registration/requests/edit-single-request/:requestId",
    neededPermissions: ["Tenant_Full_Manager", "Tenant_Basic_Manager"],
    alternativePermission: (allGroups) =>
      (allGroups.includes("Call_Of_Registration_Manager") &&
        allGroups.includes("Full_Manager")) ||
      (allGroups.includes("Call_Of_Registration_Manager") &&
        allGroups.includes("User_Editor")),
    Element: EditSingleCallOfRegistrationRequest,
  },
  //   CALL_OF_REGISTRATION
  /****
   *
   *
   *
   *
   *
   *
   */
  //   POSTS
  {
    route: "/posts",
    neededPermissions: ["Active_Users"],
    Element: Posts,
  },
  {
    route: "/posts/show-details-of-single-post",
    neededPermissions: ["Active_Users"],
    Element: ShowSinglePost,
  },
  {
    route: "/posts/add-new",
    neededPermissions: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "Post_Manager",
    ],
    Element: AddNewPost,
  },
  {
    route: "/posts/edit-single-post",
    neededPermissions: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "Post_Manager",
    ],
    Element: EditSinglePost,
  },
  //   POSTS
  /****
   *
   *
   *
   *
   *
   *
   */
  //   SECURITY_EVENTS
  {
    route: "/security-events",
    neededPermissions: ["Security_Event_Reader", "Security_Event_Manager"],
    Element: SecurityEvents,
  },
  //   SECURITY_EVENTS
  /****
   *
   *
   *
   *
   *
   *
   */
  // FORM_SAMPLE
  {
    route: "/form-samples",
    neededPermissions: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "Process_Form_Editor",
      "Process_Full_Manager",
      "Form_Sample_Manager",
    ],
    Element: FormSamples,
  },
  {
    route: "/form-samples/add-new-sample",
    neededPermissions: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "Form_Sample_Manager",
    ],
    Element: AddNewFormSample,
  },
  {
    route: "/form-samples/view-selected-form-sample",
    neededPermissions: ["Active_Users"],
    Element: ViewSingleFormSample,
  },
  {
    route: "/form-samples/edit-single-form/:formId/:persianFormTemplateName",
    neededPermissions: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "Form_Sample_Manager",
    ],
    Element: EditSingleFormSample,
  },
  // FORM_SAMPLE
  /****
   *
   *
   *
   *
   *
   *
   */
  //   ALLOCATED_PROCESSES
  {
    route: "/allocated-processes",
    neededPermissions: ["Active_Users"],
    Element: AllocatedProcesses,
  },
  {
    route:
      "/allocated-processes/start-new-instance-with-form/:processKey/:persianProcessKey",
    neededPermissions: ["Active_Users"],
    Element: ShowFormOfAllocatedProcessAndStartIt,
  },
  //   ALLOCATED_PROCESSES
  /****
   *
   *
   *
   *
   *
   *
   */
  //   HELP_CENTER
  {
    route: "/help-center",
    neededPermissions: ["Active_Users"],
    Element: HelpCenter,
  },
  //   HELP_CENTER
  /****
   *
   *
   *
   *
   *
   *
   */
];
