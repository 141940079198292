// React
import React from "react";
// React

// Contexts
import {
  SetUserGroupsContext,
  UserGroupsContext,
} from "./UserGroupsContext/UserGroupsContext";
import {
  IsFullRenderdContext,
  SetIsFullRenderdContext,
} from "./UserGroupsContext/IsFullRenderdContext";
import {
  IsSidebarOpenContext,
  SetIsSidebarOpenContext,
} from "./IsSidebarOpen/IsSidebarOpenContext";
import { ExecutingPermissionsContext } from "./ExecutingPermissionsContext/ExecutingPermissionsContext";
import {
  IsNightModeOnContext,
  SetIsNightModeOnContext,
} from "./IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Models
import {
  I_ExecutablePermissions,
  processActivityStep,
} from "../Models/interfaces";
import { IsMapInInView, SetIsMapInInView } from "./IsMapInInView/IsMapInInView";
import {
  NecessaryDataFetchingStatus,
  SetNecessaryDataFetchingStatus,
} from "./NecessaryDataFetchingStatus/NecessaryDataFetchingStatus";
import {
  AllSteps,
  ProcessHistoryContext,
  SetAllSteps,
  SetProcessHistoryContext,
  SetStepsPaginationData,
  StepsPaginationData,
  T_ProcessHistoryContext,
  T_ProcessPaginationData,
} from "./ProcessHistoryContext/ProcessHistoryContext";
// Models

type ContextsProps = {
  userGroups: string[];
  setUserGroups: React.Dispatch<React.SetStateAction<string[]>>;

  isFullRenderd: boolean;
  setIsFullRenderd: React.Dispatch<React.SetStateAction<boolean>>;

  isSidebarClosed: boolean;
  setIsSidebarClosed: React.Dispatch<React.SetStateAction<boolean>>;

  isNightMode: boolean;
  setIsNightMode: React.Dispatch<React.SetStateAction<boolean>>;

  isMapInView: boolean;
  setIsMapInView: React.Dispatch<React.SetStateAction<boolean>>;

  executePermissionsRef: React.MutableRefObject<I_ExecutablePermissions>;

  necessaryDataFetchingStatus: "pending" | "rejected" | "done";
  setNecessaryDataFetchingStatus: React.Dispatch<
    React.SetStateAction<"pending" | "rejected" | "done">
  >;

  processHistoryData: T_ProcessHistoryContext;
  setProcessHistoryData: React.Dispatch<
    React.SetStateAction<T_ProcessHistoryContext>
  >;

  allSteps: processActivityStep;
  setAllSteps: React.Dispatch<React.SetStateAction<processActivityStep>>;

  stepsPaginationData: T_ProcessPaginationData;
  setStepsPaginationData: React.Dispatch<
    React.SetStateAction<T_ProcessPaginationData>
  >;

  children: React.ReactNode;
};
const Contexts: React.FunctionComponent<ContextsProps> = ({
  isFullRenderd,
  isSidebarClosed,
  setIsFullRenderd,
  setIsSidebarClosed,
  setUserGroups,
  userGroups,
  isNightMode,
  setIsNightMode,
  isMapInView,
  setIsMapInView,
  executePermissionsRef,
  necessaryDataFetchingStatus,
  setNecessaryDataFetchingStatus,
  processHistoryData,
  setProcessHistoryData,
  allSteps,
  setAllSteps,
  setStepsPaginationData,
  stepsPaginationData,

  children,
}) => {
  return (
    <UserGroupsContext.Provider value={userGroups}>
      <SetUserGroupsContext.Provider value={setUserGroups}>
        <IsFullRenderdContext.Provider value={isFullRenderd}>
          <SetIsFullRenderdContext.Provider value={setIsFullRenderd}>
            <IsSidebarOpenContext.Provider value={isSidebarClosed}>
              <SetIsSidebarOpenContext.Provider value={setIsSidebarClosed}>
                {/* Executing Permission */}
                <ExecutingPermissionsContext.Provider
                  value={
                    executePermissionsRef.current
                  } /** ReadOnly Permissions will be in here */
                >
                  <IsNightModeOnContext.Provider value={isNightMode}>
                    <SetIsNightModeOnContext.Provider value={setIsNightMode}>
                      <IsMapInInView.Provider value={isMapInView}>
                        <SetIsMapInInView.Provider value={setIsMapInView}>
                          <NecessaryDataFetchingStatus.Provider
                            value={necessaryDataFetchingStatus}
                          >
                            <SetNecessaryDataFetchingStatus.Provider
                              value={setNecessaryDataFetchingStatus}
                            >
                              <ProcessHistoryContext.Provider
                                value={processHistoryData}
                              >
                                <SetProcessHistoryContext.Provider
                                  value={setProcessHistoryData}
                                >
                                  <AllSteps.Provider value={allSteps}>
                                    <SetAllSteps.Provider value={setAllSteps}>
                                      <StepsPaginationData.Provider
                                        value={stepsPaginationData}
                                      >
                                        <SetStepsPaginationData.Provider
                                          value={setStepsPaginationData}
                                        >
                                          {children}
                                        </SetStepsPaginationData.Provider>
                                      </StepsPaginationData.Provider>
                                    </SetAllSteps.Provider>
                                  </AllSteps.Provider>
                                </SetProcessHistoryContext.Provider>
                              </ProcessHistoryContext.Provider>
                            </SetNecessaryDataFetchingStatus.Provider>
                          </NecessaryDataFetchingStatus.Provider>
                        </SetIsMapInInView.Provider>
                      </IsMapInInView.Provider>
                    </SetIsNightModeOnContext.Provider>
                  </IsNightModeOnContext.Provider>
                </ExecutingPermissionsContext.Provider>
                {/* Executing Permission */}
              </SetIsSidebarOpenContext.Provider>
            </IsSidebarOpenContext.Provider>
          </SetIsFullRenderdContext.Provider>
        </IsFullRenderdContext.Provider>
      </SetUserGroupsContext.Provider>
    </UserGroupsContext.Provider>
  );
};

export default Contexts;
