// Redux
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// Redux

// Models
import { I_Posts } from "../../Models/interfaces";
// Models

// Modules
import { customizedToast } from "../../Utils/CustomizedToast/customizedToast";
import { getListOfPostsServices } from "../../Services/Posts/get/getListOfPostsServices";
import { addNewPostService } from "../../Services/Posts/post/addNewPostService";
import { removeSinglePostService } from "../../Services/Posts/post/removeSinglePostService";
import { editSinglePostService } from "../../Services/Posts/post/editSinglePostService";
import { resetReferenceOfObject } from "../../Utils/resetReferenceOfObject";
import { reduxCatchHandler } from "../../Utils/ErrorHandlers/reduxCatchHandler";
// Modules

const initialState: {
  postsData: {
    // AddedByDeveloper
    isPending: boolean;
    isError: boolean;
    // AddedByDeveloper
    data: I_Posts;
  };
} & {
  addingSinglePost: {
    isPending: boolean;
    isDone: boolean;
  };
} & {
  removingSinglePost: {
    isPending: boolean;
    isDone: boolean;
  };
} & {
  editingSinglePost: {
    isPending: boolean;
    isDone: boolean;
  };
} = {
  postsData: {
    isPending: true,
    isError: false,
    data: {
      content: [],
      hasNextPage: false,
      pageNumber: 1,
      pageSize: 10,
      totalElements: 1,
      totalPages: 1,
    },
  },
  addingSinglePost: {
    isDone: false,
    isPending: false,
  },
  removingSinglePost: {
    isDone: false,
    isPending: false,
  },
  editingSinglePost: {
    isDone: false,
    isPending: false,
  },
};

export const getAsyncAllPostsData = createAsyncThunk(
  "posts/getAllPostsData",
  async (_data: {
    userToken: string;
    _data: {
      page: number;
      size: number;
      tenant: string;
      placement: string;
      active: boolean | string;
      search: string;
    };
    disableError?: boolean;
  }) => {
    try {
      const { data } = await getListOfPostsServices(_data);
      return data;
    } catch (err: any) {
      reduxCatchHandler(err, _data.disableError);
      throw err;
    }
  }
);

export const addAsyncNewPost = createAsyncThunk(
  "posts/addNewPost",
  async (_data: {
    userToken: string;
    _data: {
      tenantId: string;
      placementType: string;
      active: boolean;
      data: string;
    };
  }) => {
    try {
      const { data } = await addNewPostService(_data);
      return data;
    } catch (err: any) {
      console.log(err);
      if (err?.response.data.messages) {
        for (
          let i: number = 0;
          i <= err?.response.data.messages.length - 1;
          i++
        ) {
          customizedToast(err?.response.data.messages[i].message, "error");
        }
        throw err;
      }
      throw err;
    }
  }
);

export const removeAsyncSinglePost = createAsyncThunk(
  "posts/removeSinglePost",
  async (_data: {
    userToken: string;
    _data: {
      postId: string | number;
    };
  }) => {
    try {
      const { data } = await removeSinglePostService(_data);
      return data;
    } catch (err: any) {
      console.log(err);
      if (err?.response.data.messages) {
        for (
          let i: number = 0;
          i <= err?.response.data.messages.length - 1;
          i++
        ) {
          customizedToast(err?.response.data.messages[i].message, "error");
        }
        throw err;
      }
      throw err;
    }
  }
);

export const editAsyncSinglePost = createAsyncThunk(
  "posts/editSinglePost",
  async (_data: {
    userToken: string;
    _data: {
      forSending: {
        placementType: string;
        active: boolean;
        data: string;
      };
      postId: string | number;
    };
  }) => {
    try {
      const { data } = await editSinglePostService(_data);
      return data;
    } catch (err: any) {
      console.log(err);
      if (err?.response.data.messages) {
        for (
          let i: number = 0;
          i <= err?.response.data.messages.length - 1;
          i++
        ) {
          customizedToast(err?.response.data.messages[i].message, "error");
        }
        throw err;
      }
      throw err;
    }
  }
);

export const postsSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    resetRecevedPostsDataState: (state) => {
      state.postsData.data = resetReferenceOfObject(
        initialState.postsData.data
      );
      state.postsData.isPending = true;
    },
    resetAddingPostState: (state) => {
      state.addingSinglePost.isDone = false;
      state.addingSinglePost.isPending = false;
    },
    resetRemovingPostState: (state) => {
      state.removingSinglePost.isDone = false;
      state.removingSinglePost.isPending = false;
    },
    resetEditingPostState: (state) => {
      state.editingSinglePost.isDone = false;
      state.editingSinglePost.isPending = false;
    },
  },
  extraReducers: (builder) => {
    //
    //
    //
    //
    //
    //
    builder.addCase(getAsyncAllPostsData.pending, (state, action) => {
      state.postsData.isPending = true;
      state.postsData.isError = false;
    });
    builder.addCase(getAsyncAllPostsData.fulfilled, (state, action) => {
      state.postsData.isPending = false;
      if (action.payload?.error === 0 || Boolean(action.payload) === true) {
        for (let i: number = 0; i <= action.payload!.messages.length - 1; i++) {
          customizedToast(action.payload!.messages[i].message, "success");
        }
        state.postsData.isError = false;
        state.postsData.data = action.payload!.result;
        return;
      }
    });
    builder.addCase(getAsyncAllPostsData.rejected, (state, action) => {
      state.postsData.isPending = false;
      state.postsData.isError = true;
    });
    //
    //
    //
    //
    //
    //
    builder.addCase(addAsyncNewPost.pending, (state, action) => {
      state.addingSinglePost.isDone = false;
      state.addingSinglePost.isPending = true;
    });
    builder.addCase(addAsyncNewPost.fulfilled, (state, action) => {
      state.addingSinglePost.isDone = true;
      state.addingSinglePost.isPending = false;

      if (action.payload?.error === 0 || Boolean(action.payload) === true) {
        for (let i: number = 0; i <= action.payload!.messages.length - 1; i++) {
          customizedToast(action.payload!.messages[i].message, "success");
        }
        return;
      }
    });
    builder.addCase(addAsyncNewPost.rejected, (state, action) => {
      state.addingSinglePost.isDone = false;
      state.addingSinglePost.isPending = false;
    });
    //
    //
    //
    //
    //
    //
    builder.addCase(removeAsyncSinglePost.pending, (state, action) => {
      state.removingSinglePost.isDone = false;
      state.removingSinglePost.isPending = true;
    });
    builder.addCase(removeAsyncSinglePost.fulfilled, (state, action) => {
      state.removingSinglePost.isDone = true;
      state.removingSinglePost.isPending = false;

      if (action.payload?.error === 0 || Boolean(action.payload) === true) {
        for (let i: number = 0; i <= action.payload!.messages.length - 1; i++) {
          customizedToast(action.payload!.messages[i].message, "success");
        }
        return;
      }
    });
    builder.addCase(removeAsyncSinglePost.rejected, (state, action) => {
      state.removingSinglePost.isDone = false;
      state.removingSinglePost.isPending = false;
    });
    //
    //
    //
    //
    //
    //
    builder.addCase(editAsyncSinglePost.pending, (state, action) => {
      state.editingSinglePost.isDone = false;
      state.editingSinglePost.isPending = true;
    });
    builder.addCase(editAsyncSinglePost.fulfilled, (state, action) => {
      state.editingSinglePost.isDone = true;
      state.editingSinglePost.isPending = false;

      if (action.payload?.error === 0 || Boolean(action.payload) === true) {
        for (let i: number = 0; i <= action.payload!.messages.length - 1; i++) {
          customizedToast(action.payload!.messages[i].message, "success");
        }
        return;
      }
    });
    builder.addCase(editAsyncSinglePost.rejected, (state, action) => {
      state.editingSinglePost.isDone = false;
      state.editingSinglePost.isPending = false;
    });
  },
});
