// React
import React, { useCallback, useEffect, useRef, useState } from "react";
// React

// CSS
import styles from "./ShowFormOfAllocatedProcessAndStartIt.module.css";
// CSS

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  allocatedProcessesSlice,
  getAsyncSingleAllocatedProcessStartingFormByProcessKey,
  startAsyncNewAllocatedProcessInstance,
} from "../../../Features/AllocatedProcessesSlice/allocatedProcessesSlice";
// Redux

// Components
import { Form } from "@formio/react";
import StartAllocatedProcessModal from "../StartAllocatedProcessModal/StartAllocatedProcessModal";
// Components

// Modules
import { useLocation, useNavigate, useParams } from "react-router-dom";
// Modules

// Urls
import { mainUrl } from "../../../Services/urls";
// Urls

// Utils
import { customizedToast } from "../../../Utils/CustomizedToast/customizedToast";
import { convertToGeorgianDateFormPersianDateInString } from "../../../Utils/FormioUtils/convertToGeorgianDateFormPersianDateInString";
import Swal from "sweetalert2";
// Utils

// Hooks
import usePersianDatePickerEventHandler from "../../../Hooks/usePersianDatePickerEventHandler";
import useReRenderMap from "../../../Hooks/useReRenderMap";
import { useMapRenderInLittleModal } from "../../../Hooks/MapHooks/useMapRenderInLittleModal";
import { useMapRenderInLittleModalInTasks } from "../../../Hooks/MapHooks/useMapRenderInLittleModalInTasks";
// Hooks

// DayJs
import dayJs from "dayjs";
import faLocale from "dayjs/locale/fa";
import jalaliday from "jalaliday";
// DayJs

dayJs.extend(jalaliday);

const ShowFormOfAllocatedProcessAndStartIt = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [__form, set__form] = useState<any>({
    display: "form",
    components: [],
  });

  const [formData, setFormData] = useState<any>({});
  console.log(formData);
  const dataRef = useRef<any>();

  const [isModalInScreen, setIsModalInScreen] = useState<boolean>(false);

  const [isJsonFormPending, setIsJsonFormPending] = useState<boolean>(true);
  const [isJsonFormError, setIsJsonFormError] = useState<boolean>(false);
  const { processKey, persianProcessKey, persianProcessName } = useParams();

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const {
    data,
    isPending: isFormUrlFetchingPending,
    isError: isFormUrlFetchingError,
  } = useAppSelector((state) => state.allocatedProcesses.startingFormData);

  const {
    state: { initialInformationRequired },
  } = useLocation();

  const getFormJSON = useCallback(
    () =>
      fetch(`${mainUrl}${data.url}`)
        .then((res) => res.json())
        .then((_data) => {
          set__form(_data);
          setIsJsonFormError(false);
        })
        .catch(() => {
          setIsJsonFormError(true);
        })
        .finally(() => {
          setIsJsonFormPending(false);
        }),
    [data.url]
  );

  useEffect(() => {
    const timeOut = setTimeout(() => {
      dispatch(
        getAsyncSingleAllocatedProcessStartingFormByProcessKey({
          userToken,
          _data: { processKey: String(processKey) },
        })
      );
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [dispatch, processKey, userToken]);

  useEffect(() => {
    if (data.url === "") return;
    if (!Boolean(data.url)) return;

    getFormJSON();
  }, [data, getFormJSON]);

  useEffect(() => {
    return () => {
      dispatch(
        allocatedProcessesSlice.actions.resetRecevedAllocatedProcessStartingFormData()
      );
    };
  }, [dispatch]);

  usePersianDatePickerEventHandler(isJsonFormPending);

  useMapRenderInLittleModalInTasks(isJsonFormPending);
  useMapRenderInLittleModal(isFormUrlFetchingPending);

  useReRenderMap({
    forceReRender: true,
    form: __form,
    isPending: isFormUrlFetchingPending,
  });

  return (
    <div className={`${styles.allocatedProcessStartFormContainer} `}>
      {isFormUrlFetchingPending && !isFormUrlFetchingError ? (
        <div className="d-flex align-items-center justify-content-center">
          در حال بارگیری فرم
        </div>
      ) : null}
      {!isFormUrlFetchingPending && isFormUrlFetchingError ? (
        <button
          onClick={() => {
            setIsJsonFormPending(true);
            setIsJsonFormError(false);
            dispatch(
              getAsyncSingleAllocatedProcessStartingFormByProcessKey({
                userToken,
                _data: { processKey: String(processKey) },
              })
            );
          }}
        >
          مشکلی در دریافت به وجود آمده . برای دریافت دوباره کلیک کنید
        </button>
      ) : null}
      {!isFormUrlFetchingPending && !isFormUrlFetchingError ? (
        <>
          {isJsonFormPending && !isJsonFormError ? (
            <div className="d-flex align-items-center justify-content-center">
              در حال بارگیری فرم
            </div>
          ) : null}

          {isJsonFormPending && isJsonFormError ? (
            <button
              className="d-flex align-items-center justify-content-center"
              onClick={getFormJSON}
            >
              خطا در بارگذاری فرم
            </button>
          ) : null}

          {!isJsonFormPending && !isJsonFormError ? (
            <Form
              src={__form}
              onError={() => {
                customizedToast(
                  "مشکلی در هنگام دریافت فرم به وجود آمد",
                  "error"
                );
              }}
              onChange={(data: any) => {
                setFormData(data);
                dataRef.current = data;
                console.log(data);
              }}
              onFormLoad={() => {
                customizedToast("فرم با موفقیت بارگذاری شد", "success");
              }}
              onSubmit={(data: any) => {
                console.log("submited !", dataRef.current.data.submit);
                console.log(dataRef.current.data.submit);

                if (Boolean(initialInformationRequired)) {
                  setIsModalInScreen(true);
                  return;
                }

                dispatch(
                  startAsyncNewAllocatedProcessInstance({
                    userToken,
                    _data: {
                      _data: {
                        businessKey: `${processKey}_businessKey_${dayJs()
                          .calendar("jalali")
                          .locale(faLocale)
                          .format("YYYY-MM-DD")}`,
                        title: `${
                          persianProcessKey ||
                          persianProcessName ||
                          "withoutName"
                        }_${dayJs()
                          .calendar("jalali")
                          .locale(faLocale)
                          .format("YYYY-MM-DD")}`,
                        formData: convertToGeorgianDateFormPersianDateInString(
                          JSON.stringify(dataRef.current.data)
                        )
                          ? JSON.parse(
                              convertToGeorgianDateFormPersianDateInString(
                                JSON.stringify(dataRef.current.data)
                              )
                            )
                          : dataRef.current.data,
                      },
                      processKey: processKey!,
                      onDone: (tc) => {
                        Swal.fire({
                          icon: "success",
                          title: "عملیات موفق",
                          text: `کد رهگیری : ${tc}`,
                        }).then(() => {
                          navigate("/");
                        });
                      },
                    },
                  })
                );
              }}
              onSubmitDone={() => {
                customizedToast("فرم ارسال شد", "info");
              }}
            />
          ) : null}
        </>
      ) : null}

      {isModalInScreen ? (
        <StartAllocatedProcessModal
          formData={dataRef.current.data}
          processKey={String(processKey)}
          setIsModalInScreen={setIsModalInScreen}
          persianName={persianProcessKey || persianProcessName || "withoutName"}
        />
      ) : null}
    </div>
  );
};

export default ShowFormOfAllocatedProcessAndStartIt;
