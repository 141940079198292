// React
import React from "react";
// React

// CSS
import "./ToggleBtn.css";
// CSS

type ToggleBtnProps = {
  toggleText: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  isChecked: boolean;
  containerClassName?: string;
};
const ToggleBtn: React.FunctionComponent<ToggleBtnProps> = ({
  isChecked,
  onChange,
  toggleText,
  containerClassName,
}) => {
  return (
    <label className={`toggle ${containerClassName}`}>
      <input
        className="toggle-checkbox"
        type="checkbox"
        checked={isChecked}
        onChange={onChange}
      />
      <div className="toggle-switch"></div>
      <span className="toggle-label">{toggleText}</span>
    </label>
  );
};

export default ToggleBtn;
