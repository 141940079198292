// React
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
// React

// CSS
import styles from "./AddNewProcess.module.css";
// CSS

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
// Redux

// Utils
import { Spinner } from "reactstrap";
import CustomInput1 from "../../../Utils/CustomInput1/CustomInput1";
// Utils

// Redux
import {
  addAsyncSingleProcess,
  processesSlice,
} from "../../../Features/ProcessesSlice/ProcessesSlice";
import { searchAndBackSlice } from "../../../Features/SearchAndBackSlice/searchAndBackSlice";

// Modules
import { useNavigate } from "react-router-dom";
import { permitionCheckerWithoutToast } from "../../../Modules/permitionCheckerWithoutToast";
// Modules

// Contexts
import { UserGroupsContext } from "../../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsFullRenderdContext } from "../../../Contexts/UserGroupsContext/IsFullRenderdContext";
import { ExecutingPermissionsContext } from "../../../Contexts/ExecutingPermissionsContext/ExecutingPermissionsContext";
// Contexts

// Icons
// Icons

// BPMN
import BpmnViewer from "bpmn-js";
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
import CustomSelectOption2 from "../../../Utils/CustomSelectOption2/CustomSelectOption2";
import CustomCheckbox from "../../../Utils/CustomCheckbox/CustomCheckbox";
// BPMN

const AddNewProcess = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const selectedTenant = useAppSelector(
    (state) => state.tenants.selectedTenant
  );
  const [formsData, setFormsData] = useState<{
    tenantId: string;
    fileName: string;
    bpmnXmlData: string;
    accessType: string;
    initialInformationRequired: boolean;
  }>({
    bpmnXmlData: "",
    fileName: "",
    tenantId: selectedTenant.tenantId,
    accessType: "PRIVATE",
    initialInformationRequired: false,
  });

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const { isSendingProcessPending, isAddingProcessDone: isDone } =
    useAppSelector((state) => state.processes);

  const allGroups = useContext(UserGroupsContext);
  const permitions = useContext(ExecutingPermissionsContext);
  const isFullRenderd = useContext(IsFullRenderdContext);
  const isNightModeOn = useContext(IsNightModeOnContext);

  const xmlInputFileRef = useRef<HTMLInputElement>(null);

  const [viewerReference, setViewerReference] = useState<any>();
  const [isPending, setIsPending] = useState<boolean>(true);
  const [zoomLevel, setZoomLevel] = useState(1);

  const canvasRef = useRef<any>(null);

  const bpmnLoader = useCallback(
    async function x(viewer: any) {
      try {
        const result = await viewer.importXML(formsData.bpmnXmlData);
        const { warnings } = result;
        console.log(warnings);
      } catch (err: any) {
        console.log(err.message, err.warnings);
      }
    },
    [formsData.bpmnXmlData]
  );

  const handleXMLFileChange = (e: any) => {
    const file = e.target.files[0];

    setFormsData((prevState) => ({ ...prevState, fileName: file.name }));

    if (file) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const content = event.target.result;
        setFormsData((prevState) => ({ ...prevState, bpmnXmlData: content }));
      };
      reader.readAsText(file, "UTF-8");
    }
  };

  const handleZoom = (factor: number) => {
    if (viewerReference) {
      const newZoomLevel = zoomLevel + factor;
      setZoomLevel(newZoomLevel);

      const canvas = viewerReference.get("canvas");
      const oldViewbox = canvas.viewbox();
      const zoomedViewbox = {
        ...oldViewbox,
        width: oldViewbox.width / canvas.zoom(),
        height: oldViewbox.height / canvas.zoom(),
      };

      canvas.zoom(newZoomLevel, zoomedViewbox);
    }
  };

  const handleZoomIn = () => {
    handleZoom(0.1);
  };

  const handleZoomOut = () => {
    handleZoom(-0.1);
  };

  useEffect(() => {
    dispatch(searchAndBackSlice.actions.changeCurrLocation("افزودن فرآیند"));
  }, [dispatch]);

  useEffect(() => {
    if (formsData.bpmnXmlData === "") return;
    const container = document.getElementById("bpmn-container");
    const viewer = new BpmnViewer({ container });
    const currentProcessState = viewer.getDefinitions();
    console.log(currentProcessState);
    const eventBus = viewer.get("eventBus");
    eventBus.on("element.click", (event: any) => {
      const element = event.element;
      if (element.type === "bpmn:Task") {
        console.log("Task clicked:", element.id);
      }
    });
    setViewerReference(viewer);
    viewer.get("canvas").zoom("fit-viewport", "auto");
    setIsPending(false);

    // Path to your BPMN file

    // Load and display the BPMN diagram
    // viewer.importXML(bpmnXML, (err: any) => {
    //   if (err) {
    //     console.error("Error rendering BPMN diagram.", err);
    //   }
    // });

    bpmnLoader(viewer);
    return () => {
      viewer.destroy();
    };
  }, [formsData.bpmnXmlData, bpmnLoader]);

  useEffect(() => {
    if (viewerReference) {
      const canvas = viewerReference.get("canvas");
      canvasRef.current = canvas;

      const container: any = document.getElementById("bpmn-container");

      let isDragging = false;
      let startPos = { x: 0, y: 0 };
      let accumulatedDisplacement = { x: 0, y: 0 };
      let oldViewbox: any;

      const handleMouseDown = (event: any) => {
        isDragging = true;
        startPos = { x: event.clientX, y: event.clientY };
        oldViewbox = canvas.viewbox();
      };

      const handleMouseMove = (event: any) => {
        if (!isDragging) return;

        const dx = event.clientX - startPos.x;
        const dy = event.clientY - startPos.y;

        accumulatedDisplacement.x += dx;
        accumulatedDisplacement.y += dy;

        const newX = oldViewbox.x - accumulatedDisplacement.x;
        const newY = oldViewbox.y - accumulatedDisplacement.y;

        canvas.viewbox({ ...oldViewbox, x: newX, y: newY });

        startPos = { x: event.clientX, y: event.clientY };
      };

      const handleMouseUp = () => {
        isDragging = false;
        accumulatedDisplacement = { x: 0, y: 0 };
      };

      container.addEventListener("mousedown", handleMouseDown);
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);

      container.addEventListener("pointerdown", handleMouseDown);
      window.addEventListener("pointermove", handleMouseMove);
      window.addEventListener("pointerup", handleMouseUp);
      return () => {
        container.removeEventListener("mousedown", handleMouseDown);
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleMouseUp);

        container.removeEventListener("pointerdown", handleMouseDown);
        window.removeEventListener("pointermove", handleMouseMove);
        window.removeEventListener("pointerup", handleMouseUp);
      };
    }
  }, [viewerReference]);

  const { processPage } = useAppSelector((state) => state.searchAndBack);

  useEffect(() => {
    if (!isDone) return;

    navigate(
      `/processes?page=${processPage.pageNumber}&size=${processPage.size}`
    );
  }, [isDone, navigate, processPage]);

  useEffect(() => {
    return () => {
      dispatch(processesSlice.actions.resetIsAddingProcessDone());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isPending) return;
    viewerReference.get("canvas").zoom("fit-viewport");
  }, [viewerReference, isPending]);

  return (
    <>
      <div
        className={`${
          styles.addNewProcessContainer
        } w-100 d-flex flex-column align-items-center ${
          isNightModeOn ? "nightBg1" : ""
        }`}
      >
        <div className="d-flex align-self-start align-items-center justify-content-between flex-row w-100">
          <button
            className={` operationEvent  easyCreateBtn `}
            onClick={() => {
              navigate(`/processes/new-process/fast-create`);
            }}
          >
            ورود به محیط ساخت سریع
          </button>
          <button
            className={`btn btn-secondary operationEvent  `}
            onClick={() => {
              if (!xmlInputFileRef.current) return;
              xmlInputFileRef.current.click();
            }}
          >
            آپلود فایل جدید
          </button>
        </div>
        <div className={`${styles.addItemsContainer} w-100`}>
          <div
            className={`${styles.rows} w-100 d-flex align-items-center justify-content-between flex-wrap `}
          >
            <CustomCheckbox
              isChecked={formsData.initialInformationRequired}
              onChange={() => {
                setFormsData((prevState) => ({
                  ...prevState,
                  initialInformationRequired:
                    !prevState.initialInformationRequired,
                }));
              }}
              onClick={() => {
                setFormsData((prevState) => ({
                  ...prevState,
                  initialInformationRequired:
                    !prevState.initialInformationRequired,
                }));
              }}
              title="درخواست فرم شروع"
            />
            <div className="d-flex flex-row align-items-center ">
              <CustomSelectOption2
                getSelectedOption={(data) => {
                  setFormsData((prevState) => ({
                    ...prevState,
                    accessType: String(data),
                  }));
                }}
                initialTitle="نوع فرآیند (پیشفرض : خصوصی )"
                initialValue={"PRIVATE"}
                options={[
                  {
                    title: "عمومی",
                    value: "PUBLIC",
                  },
                  {
                    title: "خصوصی",
                    value: "PRIVATE",
                  },
                ]}
              />

              <p
                style={{
                  margin: "unset",
                  marginLeft: "1rem",
                }}
              >
                نوع فرآیند
              </p>
            </div>

            {permitionCheckerWithoutToast(
              allGroups,
              permitions.canSeeTenantTextBox,
              isFullRenderd
            ) && (
              <CustomInput1
                inputType="text"
                onBlur={() => {
                  return false;
                }}
                onChange={(e) => {
                  return false;
                }}
                placeHolder="نام سازمان"
                //  keyForShowOnTop="نام سازمان"
                dontShowSearchIcon
                value={selectedTenant.tenantTitle}
                defaultValue={selectedTenant.tenantTitle}
                disabled
              />
            )}

            <CustomInput1
              inputType="text"
              onBlur={() => {}}
              onChange={(e) => {
                setFormsData((prevState) => ({
                  ...prevState,
                  fileName: e.target.value,
                }));
              }}
              placeHolder="نام فایل"
              //  keyForShowOnTop="نام فایل"
              dontShowSearchIcon
              value={formsData.fileName}
              defaultValue={formsData.fileName}
            />
          </div>
          <input
            type="file"
            className="form-control"
            id="floatingInput"
            placeholder="name@example.com"
            accept=".json,application/json,.bpmn,.xml,application/xml"
            multiple={false}
            onChange={(e) => {
              handleXMLFileChange(e);
            }}
            value={undefined}
            style={{ display: "none" }}
            ref={xmlInputFileRef}
          />
        </div>
        <div className="w-100 d-flex align-items-center justify-content-between mt-4">
          <button
            className={` operationEvent   submitBtn  `}
            onClick={() => {
              if (
                !permitionCheckerWithoutToast(
                  allGroups,
                  [
                    "Tenant_Full_Manager",
                    "Tenant_Basic_Manager",
                    "Process_Full_Manager",
                    "Process_Editor",
                  ],
                  isFullRenderd
                )
              )
                return;
              dispatch(
                addAsyncSingleProcess({
                  _data: formsData,
                  userToken,
                })
              );
            }}
          >
            {isSendingProcessPending ? (
              <>
                <Spinner />
              </>
            ) : (
              <>تایید</>
            )}
          </button>
          <div className="d-flex flex-row align-items-center ">
            <p
              className="mt-3"
              style={{
                direction: "rtl",
              }}
            >
              {Number(xmlInputFileRef.current?.files!.length) > 0
                ? `نام فایل : ${xmlInputFileRef.current?.files![0].name}`
                : ""}
            </p>
          </div>
        </div>
      </div>
      {formsData.bpmnXmlData !== "" ? (
        <>
          <div
            className={` d-flex flex-row align-items-start mt-2  w-100 justify-content-end mt-4 ${styles.zoomContainer}`}
          >
            <button
              onClick={handleZoomIn}
              className={` operationEvent  zoomInBtn`}
            >
              <span className="icon-search-zoom-in"></span>
            </button>
            <button
              onClick={handleZoomOut}
              className={` operationEvent zoomOutBtn`}
            >
              <span className="icon-search-zoom-out"></span>
            </button>
          </div>
          <div
            id="bpmn-container"
            style={{ height: "600px", width: "100%", cursor: "grab" }}
          ></div>
        </>
      ) : null}
    </>
  );
};

export default AddNewProcess;
