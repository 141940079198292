// React
import React, {
  useCallback,
  useContext,
  useEffect,
  // useEffect,
  useState,
} from "react";
// React

// CSS
import styles from "./ShowTasksByProcessId.module.css";
// CSS

// Modules
import { Link, useParams, useSearchParams } from "react-router-dom";
// Modules

// Utils
import { Spinner } from "reactstrap";
import CustomInput1 from "../../../Utils/CustomInput1/CustomInput1";
import Pagination from "../../../Utils/Pagination/Pagination";
// Utils

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  getAsyncProcessTasks,
  processesSlice,
  // processesSlice,
} from "../../../Features/ProcessesSlice/ProcessesSlice";
import { searchAndBackSlice } from "../../../Features/SearchAndBackSlice/searchAndBackSlice";
// Redux

// Components
// Components

// Modules
import { permitionCheckerWithoutToast } from "../../../Modules/permitionCheckerWithoutToast";
// Modules

// Icons
import { BsDashLg, BsListCheck } from "react-icons/bs";
// Icons

// Contexts
import { UserGroupsContext } from "../../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsFullRenderdContext } from "../../../Contexts/UserGroupsContext/IsFullRenderdContext";
import { ExecutingPermissionsContext } from "../../../Contexts/ExecutingPermissionsContext/ExecutingPermissionsContext";
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Icons
import { MdDone } from "react-icons/md";
import { FaAngleDown } from "react-icons/fa6";
// Icons

const ShowTasksByProcessId = () => {
  const { processKey, persianProcessName } = useParams();

  const dispatch = useAppDispatch();

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const taskData = useAppSelector((state) => state.processes.processTasks);

  const [isDescending, setIsDescending] = useState<boolean>(false);

  const [isMounted, setIsMounted] = useState<boolean>(false);

  const permitions = useContext(ExecutingPermissionsContext);
  const allGroups = useContext(UserGroupsContext);
  const isNightOn = useContext(IsNightModeOnContext);

  //

  const selectedTenant = useAppSelector(
    (state) => state.tenants.selectedTenant
  );

  const [searchParam, setSearchParam] = useSearchParams();

  const [formsData, setFormsData] = useState<{
    page: number;
    size: number;
    orderByDueDate: boolean;
    orderByFollowUpDate: boolean;
    orderByPriority: boolean;
    descendingSort: boolean;
    tenant: string;
    userName: string;
    search: string;
  }>({
    page: Number(searchParam.get("page")) || 1,
    size: Number(searchParam.get("size")) || 10,
    orderByDueDate: searchParam.get("sort") === "3",
    orderByFollowUpDate: searchParam.get("sort") === "4",
    orderByPriority: searchParam.get("sort") === "5",
    descendingSort: searchParam.get("sort") === "2",
    tenant: selectedTenant.tenantId,
    userName: searchParam.get("userName") || "",
    search: searchParam.get("search") || "",
  });

  const [sortShowArrowStatusOptions, setShowSortArrowStatusOptions] = useState<{
    forPriority: boolean;
    forDueDate: boolean;
    forFollowUpdate: boolean;
    orderByCreateDate: boolean;
    forDesendingSort: boolean;
    forOrderByDueDate: boolean;
  }>({
    forPriority: false,
    forDueDate: false,
    forFollowUpdate: false,
    orderByCreateDate: false,
    forDesendingSort: false,
    forOrderByDueDate: false,
  });

  const currLanguage = useAppSelector((state) => state.currLanguage.language);

  const isFullRenderd = useContext(IsFullRenderdContext);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (!isMounted) return;
    const timeOut = setTimeout(() => {
      dispatch(
        getAsyncProcessTasks({
          _data: {
            ...formsData,
            processKey: String(processKey),
            userName: formsData.userName,
          },
          userToken,
        })
      );
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [
    formsData,
    dispatch,
    processKey,
    userToken,
    allGroups,
    isMounted,
    isFullRenderd,
  ]);

  const handleShowOrRemoveSortIcon = useCallback(
    (
      type: "show" | "remove",
      selectedKey: keyof typeof sortShowArrowStatusOptions
    ) => {
      if (type === "show") {
        return setShowSortArrowStatusOptions((prevState) => {
          const keys: (keyof typeof prevState)[] = Object.keys(
            prevState
          ) as (keyof typeof prevState)[];
          const res: Record<keyof typeof prevState, boolean> = { ...prevState };

          keys.forEach((item) => {
            res[item] = false;
          });
          return {
            ...res,
            [selectedKey]: true,
          };
        });
      }
      if (type === "remove") {
        return setShowSortArrowStatusOptions((prevState) => {
          const keys: (keyof typeof prevState)[] = Object.keys(
            prevState
          ) as (keyof typeof prevState)[];
          const res: Record<keyof typeof prevState, boolean> = { ...prevState };

          keys.forEach((item) => {
            res[item] = false;
          });
          return res;
        });
      }

      return () => {};
    },
    []
  );

  useEffect(() => {
    setSearchParam(
      {
        page: String(formsData.page) || 1 + "",
        size: String(formsData.size) || 10 + "",
        userName: formsData.userName,
        search: formsData.search,
        sort: (() => {
          if (formsData.descendingSort) {
            return "2";
          }
          if (formsData.orderByDueDate) {
            return "3";
          }
          if (formsData.orderByFollowUpDate) {
            return "4";
          }
          if (formsData.orderByPriority) {
            return "5";
          }

          return "";
        })(),
      },
      {
        replace: true,
      }
    );
  }, [formsData, setSearchParam, searchParam]);

  useEffect(() => {
    dispatch(processesSlice.actions.resetTasks());
  }, [dispatch]);

  useEffect(() => {
    dispatch(searchAndBackSlice.actions.changeCurrLocation("نمایش وظایف"));
  }, [dispatch]);

  return (
    <div
      className={`${styles.ShowTasksByProcessIdContainer}  ${
        isNightOn ? "nightBg1" : ""
      }`}
    >
      <p className={styles.mainTopName}>وظایف</p>
      <p className={styles.subTopName}>
        مشاهده وظایف فرآیند{" "}
        {persianProcessName ? `: ${persianProcessName}` : `مربوطه`}
      </p>
      <div
        className={`${styles.searchItemsContent} w-100 mt-3 d-flex flex-row align-items-center justify-content-between flex-wrap`}
      >
        {/* <CustomSelectOption2
          getSelectedOption={(data) => {
            if (Number(data) === 0) {
              setFormsData((prevState) => ({
                ...prevState,
                orderByDueDate: false,
                orderByFollowUpDate: false,
                descendingSort: false,
                orderByPriority: false,
              }));
              return;
            } else if (Number(data) === 1) {
              setFormsData((prevState) => ({
                ...prevState,
                orderByDueDate: false,
                orderByFollowUpDate: false,
                descendingSort: false,
                orderByPriority: false,
              }));
              return;
            }
            // else if (Number(data) === 2) {
            //   setFormsData((prevState) => ({
            //     ...prevState,
            //     orderByDueDate: false,
            //     orderByFollowUpDate: false,
            //     descendingSort: true,
            //     orderByPriority: false,
            //   }));
            //   return;
            // }
            // else if (Number(data) === 3) {
            //   setFormsData((prevState) => ({
            //     ...prevState,
            //     orderByDueDate: true,
            //     orderByFollowUpDate: false,
            //     descendingSort: false,
            //     orderByPriority: false,
            //   }));
            //   return;
            // } 
            // else if (Number(data) === 4) {
            //   setFormsData((prevState) => ({
            //     ...prevState,
            //     orderByDueDate: false,
            //     orderByFollowUpDate: true,
            //     descendingSort: false,
            //     orderByPriority: false,
            //   }));
            //   return;
            // } 
            else if (Number(data) === 5) {
              setFormsData((prevState) => ({
                ...prevState,
                orderByDueDate: false,
                orderByFollowUpDate: false,
                descendingSort: false,
                orderByPriority: true,
              }));
              return;
            }
          }}
          initialTitle={(() => {
            if (formsData.descendingSort) {
              return "ترتیب-صعودی";
            }
            if (formsData.orderByDueDate) {
              return "سررسید";
            }
            if (formsData.orderByFollowUpDate) {
              return "جدیدترین";
            }
            if (formsData.orderByPriority) {
              return "اهمیت";
            }
            return "ترتیب را انتخاب کنید";
          })()}
          initialValue={""}
          options={[
            {
              title: "ترتیب-صعودی",
              value: "1",
            },
            {
              title: "ترتیب-نزولی",
              value: "2",
            },
            {
              title: "سررسید",
              value: "3",
            },
            {
              title: "جدیدترین",
              value: "4",
            },
            {
              title: "اهمیت",
              value: "5",
            },
          ]}
        /> */}

        {permitionCheckerWithoutToast(
          allGroups,
          permitions.canSeeTenantTextBox,
          isFullRenderd
        ) && (
          <CustomInput1
            onBlur={(e) => {
              return false;
            }}
            value={selectedTenant.tenantTitle}
            defaultValue={selectedTenant.tenantTitle}
            inputType="text"
            onChange={() => {
              return false;
            }}
            placeHolder="نام سازمان"
            //  keyForShowOnTop="نام سازمان"
            dontShowSearchIcon
            disabled
          />
        )}
        <CustomInput1
          defaultValue={formsData.userName}
          onBlur={() => {}}
          value={formsData.userName}
          inputType="text"
          onChange={(e) => {
            setFormsData((prevState) => ({
              ...prevState,
              userName: e.target.value,
            }));
          }}
          placeHolder="جستجوی نام کاربری"
          // keyForShowOnTop="نام کاربر"
          containerStyle={{
            width: "30%",
          }}
        />
        <CustomInput1
          defaultValue={formsData.search}
          onBlur={() => {}}
          value={formsData.search}
          inputType="text"
          onChange={(e) => {
            setFormsData((prevState) => ({
              ...prevState,
              search: e.target.value,
            }));
          }}
          placeHolder="جستجو"
          // keyForShowOnTop="نام کاربر"
          containerStyle={{
            width: "30%",
          }}
        />
      </div>
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/* TABLE */}

      {taskData.isPending ? (
        <div className="w-100 d-flex flex-column align-items-center justify-content-center">
          <p>در حال بارگذاری</p>
          <Spinner />
        </div>
      ) : (
        <>
          {taskData.content.length === 0 ? (
            <div className="d-flex ئف-4 w-100 flex-column align-items-center justify-content-center ">
              موردی وجود ندارد
            </div>
          ) : (
            <>
              {window.innerWidth > 1000 ? (
                <div className="w-100">
                  {taskData.content.length === 0 ? (
                    <div className="w-100 d-flex align-items-center justify-content-center mt-4">
                      موردی جهت نمایش وجود ندارد
                    </div>
                  ) : (
                    <div
                      className={`${styles.myTable} ${
                        isNightOn ? "nightBg2 pcNight" : ""
                      } w-100 d-flex flex-column align-items-center align-items-center`}
                    >
                      <div
                        className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between align-items-center`}
                      >
                        <p className={`${styles.operationTab}`}>عملیات</p>
                        <p
                          className={`${styles.priority}`}
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={
                            !isDescending ? "صعودی" : "نزولی"
                          }
                          onClick={() => {
                            if (isDescending) {
                              const _tasks = [...taskData.content];
                              _tasks.sort(
                                (a, b) =>
                                  a.priorityType.maxValue -
                                  b.priorityType.minValue
                              );
                              setIsDescending(false);
                              dispatch(
                                processesSlice.actions.changeSortOfProcessTasks(
                                  _tasks
                                )
                              );
                              return;
                            } else {
                              const _tasks = [...taskData.content];
                              _tasks.sort(
                                (a, b) =>
                                  b.priorityType.maxValue -
                                  a.priorityType.minValue
                              );
                              setIsDescending(true);
                              dispatch(
                                processesSlice.actions.changeSortOfProcessTasks(
                                  _tasks
                                )
                              );
                            }
                          }}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          اولویت
                        </p>
                        <p
                          className={`${styles.timeToFix}`}
                          onClick={() => {
                            setFormsData((prevState) => ({
                              ...prevState,
                              orderByDueDate: !prevState.orderByDueDate,
                              orderByFollowUpDate: false,
                              descendingSort: false,
                              orderByPriority: false,
                            }));
                          }}
                          onMouseEnter={() => {
                            handleShowOrRemoveSortIcon(
                              "show",
                              "forOrderByDueDate"
                            );
                          }}
                          onMouseLeave={() => {
                            handleShowOrRemoveSortIcon(
                              "remove",
                              "forOrderByDueDate"
                            );
                          }}
                        >
                          {!formsData.orderByDueDate &&
                            sortShowArrowStatusOptions.forOrderByDueDate && (
                              <FaAngleDown
                                style={{
                                  transform: "rotate(180deg)",
                                }}
                              />
                            )}
                          {formsData.orderByDueDate && (
                            <FaAngleDown
                              style={{
                                transform: !formsData.orderByDueDate
                                  ? "rotate(180deg)"
                                  : "",
                              }}
                            />
                          )}
                          تاریخ سررسید
                        </p>
                        <p
                          className={`${styles.taskStart}`}
                          onClick={() => {
                            setFormsData((prevState) => ({
                              ...prevState,
                              orderByDueDate: false,
                              orderByFollowUpDate:
                                !prevState.orderByFollowUpDate,
                              descendingSort: false,
                              orderByPriority: false,
                            }));
                          }}
                          onMouseEnter={() => {
                            handleShowOrRemoveSortIcon(
                              "show",
                              "forFollowUpdate"
                            );
                          }}
                          onMouseLeave={() => {
                            handleShowOrRemoveSortIcon(
                              "remove",
                              "forFollowUpdate"
                            );
                          }}
                        >
                          {!formsData.orderByFollowUpDate &&
                            sortShowArrowStatusOptions.forFollowUpdate && (
                              <FaAngleDown
                                style={{
                                  transform: "rotate(180deg)",
                                }}
                              />
                            )}
                          {formsData.orderByFollowUpDate && (
                            <FaAngleDown
                              style={{
                                transform: !formsData.orderByFollowUpDate
                                  ? "rotate(180deg)"
                                  : "",
                              }}
                            />
                          )}
                          زمان شروع وظیفه
                        </p>
                        <p className={`${styles.description}`}>توضیحات</p>
                        <p
                          className={`${styles.processInstanceTitle} cursor-pointer`}
                          onClick={() => {
                            setFormsData((prevState) => ({
                              ...prevState,
                              orderByDueDate: false,
                              orderByFollowUpDate: false,
                              descendingSort: !prevState.descendingSort,
                              orderByPriority: false,
                            }));
                          }}
                          onMouseEnter={() => {
                            handleShowOrRemoveSortIcon(
                              "show",
                              "forDesendingSort"
                            );
                          }}
                          onMouseLeave={() => {
                            handleShowOrRemoveSortIcon(
                              "remove",
                              "forDesendingSort"
                            );
                          }}
                        >
                          {!formsData.descendingSort &&
                            sortShowArrowStatusOptions.forDesendingSort && (
                              <FaAngleDown
                                style={{
                                  transform: "rotate(180deg)",
                                }}
                              />
                            )}
                          {formsData.descendingSort && (
                            <FaAngleDown
                              style={{
                                transform: !formsData.descendingSort
                                  ? "rotate(180deg)"
                                  : "",
                              }}
                            />
                          )}
                          عنوان
                        </p>
                        <p className={`${styles.taskName}`}>نام وظیفه</p>
                      </div>
                      <div
                        className={`${styles.tableBody} w-100 d-flex align-items-center flex-column`}
                      >
                        {taskData.content.map((item) => (
                          <div
                            key={item.taskId}
                            className={`${styles.tableData}  } w-100 d-flex align-items-center justify-content-between align-items-center`}
                          >
                            <p className={`${styles.operationTab}`}>
                              {permitionCheckerWithoutToast(
                                allGroups,
                                [
                                  "Tenant_Full_Manager",
                                  "Tenant_Basic_Manager",
                                  "Process_Full_Manager",
                                ],
                                isFullRenderd
                              ) && (
                                <Link
                                  to={`/processes/view-more-about-selected-task/${processKey}/${item.taskId}`}
                                  className={` operationEvent taskBtn`}
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content={currLanguage.showTask}
                                >
                                  <BsListCheck />
                                </Link>
                              )}
                            </p>
                            <p className={`${styles.priority} truncate`}>
                              {item.priorityType.title}
                            </p>
                            <p
                              className={`${styles.timeToFix} truncate`}
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={item?.dueDate?.jalaliDate}
                            >
                              {item?.dueDate?.jalaliDate || "موردی وجود ندارد"}
                            </p>
                            <p
                              className={`${styles.taskStart} truncate`}
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={
                                item?.createDate?.jalaliDate
                              }
                            >
                              {item?.createDate?.jalaliDate ||
                                "موردی وجود ندارد"}
                            </p>
                            <p
                              className={`${styles.description} rtl truncate`}
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={item.description}
                            >
                              {Boolean(item.description)
                                ? item.description.length >= 20
                                  ? "..." + item.description.substring(0, 19)
                                  : item.description
                                : "بدون توضیحات"}
                            </p>
                            <p
                              className={`${styles.processInstanceTitle} rtl truncate`}
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={item.processInstanceTitle}
                            >
                              {Boolean(item.processInstanceTitle)
                                ? item.processInstanceTitle.length >= 20
                                  ? "..." +
                                    item.processInstanceTitle.substring(0, 19)
                                  : item.processInstanceTitle
                                : "بدون نام"}
                            </p>
                            <p className={`${styles.taskName}`}>
                              {item.taskTitle}
                            </p>
                          </div>
                        ))}
                      </div>
                      <div className={`${styles.tableFooter}`}></div>
                    </div>
                  )}
                </div>
              ) : (
                <div
                  className={`${styles.cards} mt-4 w-100 d-flex flex-column align-items-center`}
                >
                  <>
                    {taskData.content.map((item) => (
                      <div key={item.taskId} className="w-100">
                        <div
                          className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between `}
                        >
                          <p>{item.taskTitle}</p>
                          <p>نام وظیفه </p>
                        </div>
                        <div
                          className={`${styles.tableRow} ${
                            isNightOn ? "nightBg2" : ""
                          } w-100 d-flex align-items-center justify-content-between `}
                        >
                          <p>
                            <span>توضیحات</span>
                            <span>{item.description || "فاقد توضیحات"}</span>
                          </p>
                          <p>
                            <span>بروزرسانی</span>
                            <span>
                              {item.followUpDate ? (
                                <MdDone
                                  style={{
                                    fontSize: "2rem",
                                  }}
                                />
                              ) : (
                                <BsDashLg
                                  style={{
                                    fontSize: "2rem",
                                  }}
                                />
                              )}
                            </span>
                          </p>
                          <p>
                            <span>سررسید</span>
                            <span>
                              {item.dueDate?.jalaliDate || "بدون تاریخ"}
                            </span>
                          </p>
                          <p>
                            <span>اولویت</span>
                            <span>{item.priorityType.title}</span>
                          </p>

                          {permitionCheckerWithoutToast(
                            allGroups,
                            [
                              "Tenant_Full_Manager",
                              "Tenant_Basic_Manager",
                              "Process_Full_Manager",
                            ],
                            isFullRenderd
                          ) && (
                            <p>
                              <span>عملیات</span>
                              <Link
                                to={`/processes/view-more-about-selected-task/${processKey}/${item.taskId}`}
                                className={` operationEvent taskBtn`}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={currLanguage.showTask}
                              >
                                <BsListCheck />
                              </Link>
                            </p>
                          )}
                        </div>
                      </div>
                    ))}
                  </>
                </div>
              )}
            </>
          )}
        </>
      )}
      <div className="w-100 " style={{ direction: "ltr" }}>
        <Pagination
          contaienrClassName={"flex-row-reverse"}
          dataForPagination={{
            currPage: formsData.page,
            size: formsData.size,
            totalPages: taskData.totalPages,
          }}
          onPageNumberChange={(page) => {
            setFormsData((prevState) => ({
              ...prevState,
              page,
            }));
            dispatch(
              searchAndBackSlice.actions.setProcessTasksPagePageNumberAdSize({
                page,
                size: formsData.size,
              })
            );
          }}
          onPageNumbersClick={(page) => {
            setFormsData((prevState) => ({
              ...prevState,
              page,
            }));
            dispatch(
              searchAndBackSlice.actions.setProcessTasksPagePageNumberAdSize({
                page,
                size: formsData.size,
              })
            );
          }}
          onPageSizeChange={(size) => {
            setFormsData((prevState) => ({
              ...prevState,
              size,
            }));
            dispatch(
              searchAndBackSlice.actions.setProcessTasksPagePageNumberAdSize({
                page: formsData.page,
                size,
              })
            );
          }}
          search={formsData.userName}
        />
      </div>
      {/* TABLE */}
    </div>
  );
};

export default ShowTasksByProcessId;
