// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./Users.module.css";
// CSS

// Redux
import { useAppDispatch, useAppSelector } from "../../app/store";
import {
  accessibleUsersListSlice,
  getAsyncAccessibleUsers,
} from "../../Features/AccessibleUsersListSlice/AccessibleUsersListSlice";
import { searchAndBackSlice } from "../../Features/SearchAndBackSlice/searchAndBackSlice";
import { singleAccessibleUserSlice } from "../../Features/SingleAccessibleUserSlice/singleAccessibleUserSlice";
// Redux

// Utils
import Spinner from "../../Utils/Spinner/Spinner";
import CustomInput1 from "../../Utils/CustomInput1/CustomInput1";
import MultipleSelect from "../../Utils/MultipleSelect/MultipleSelect";
import { CSVLink } from "react-csv";
// Utils

// Modules
import Pagination from "../../Utils/Pagination/Pagination";
import { permitionCheckerWithoutToast } from "../../Modules/permitionCheckerWithoutToast";
import { Link, useSearchParams } from "react-router-dom";
// Modules

// Contexts
import { UserGroupsContext } from "../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsFullRenderdContext } from "../../Contexts/UserGroupsContext/IsFullRenderdContext";
import { ExecutingPermissionsContext } from "../../Contexts/ExecutingPermissionsContext/ExecutingPermissionsContext";
// Contexts

// Icons
import { IsNightModeOnContext } from "../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Icons

// Themes
import { themeColor } from "../../Constants/constants";
import CustomSelectOption2 from "../../Utils/CustomSelectOption2/CustomSelectOption2";
// Themes

const Users = () => {
  // Constants
  const dispatch = useAppDispatch();

  const [searchParam, setSearchParam] = useSearchParams();

  const accessbileUserData = useAppSelector((state) => state.accessibleUsers);
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const { allGroupsWithTitles } = useAppSelector(
    (state) => state.userInformation
  );
  const selectedTenant = useAppSelector(
    (state) => state.tenants.selectedTenant
  );

  const isFullRenderd = useContext(IsFullRenderdContext);
  const allGroups = useContext(UserGroupsContext);
  const permitions = useContext(ExecutingPermissionsContext);
  const isNightOn = useContext(IsNightModeOnContext);

  // Constants
  /**************************** */
  /**************************** */
  /**************************** */
  /**************************** */
  //   Local States
  const [isSearchToolsOpen, setIsSearchToolsOpen] = useState<boolean>(true);
  const [fetchUserOptions, setFetchingUserOptions] = useState<{
    page: number;
    size: number;
    editing: boolean;
    search: string;
    group: string;
    tenant: string;
    usernames: string[];
  }>({
    page: Number(searchParam.get("page")) || 1,
    size: Number(searchParam.get("size")) || 10,
    editing: false,
    search: searchParam.get("search") || "",
    group: searchParam.get("group") || "",
    tenant: selectedTenant.tenantId,
    usernames: searchParam.get("users")
      ? searchParam.get("users")!.split(",")
      : [],
  });
  const [isMounted, setIsMounted] = useState<boolean>(false);
  //
  //   Local States
  /**************************** */
  /**************************** */
  /**************************** */
  /**************************** */
  //   Life Cycles
  //
  useEffect(() => {
    setIsMounted(true);
  }, []);
  //
  useEffect(() => {
    dispatch(searchAndBackSlice.actions.changeCurrLocation("کاربران"));
  }, [dispatch]);
  //
  useEffect(() => {
    if (!isMounted) return;
    const timeOut = setTimeout(() => {
      if (
        permitionCheckerWithoutToast(
          allGroups,
          [
            "Full_Manager",
            "Tenant_Full_Manager",
            "Tenant_Basic_Manager",
            "User_Editor",
            "User_Information_Reader",
          ],
          isFullRenderd
        ) === false
      )
        return;
      dispatch(
        getAsyncAccessibleUsers({
          ...fetchUserOptions,
          usernames: fetchUserOptions.usernames,
          userToken,
        })
      );
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [
    allGroups,
    dispatch,
    fetchUserOptions,
    userToken,
    isFullRenderd,
    isMounted,
  ]);
  //
  useEffect(() => {
    dispatch(
      searchAndBackSlice.actions.setUsersPageData({
        userGroup: fetchUserOptions.group,
        userSearchQuery: fetchUserOptions.search,
        userTenantId: fetchUserOptions.tenant,
        page: fetchUserOptions.page,
        size: fetchUserOptions.size,
      })
    );
    setSearchParam(
      {
        page: fetchUserOptions.page + "",
        size: fetchUserOptions.size + "",
        users: fetchUserOptions.usernames.toString(),
        search: fetchUserOptions.search,
        group: fetchUserOptions.group,
      },
      {
        replace: true,
      }
    );
  }, [dispatch, fetchUserOptions, setSearchParam]);
  //
  useEffect(() => {
    return () => {
      dispatch(accessibleUsersListSlice.actions.setUsersLoadingPending());
    };
  }, [dispatch]);
  //
  useEffect(() => {
    dispatch(singleAccessibleUserSlice.actions.resetGetUserDataPending());
    dispatch(singleAccessibleUserSlice.actions.resetRecevedUserData());
  }, [dispatch]);
  //

  const csvReport = {
    headers: [
      {
        label: "ایمیل",
        key: "email",
      },
      {
        label: "نام کاربری",
        key: "username",
      },
      {
        label: "نام خانوادگی",
        key: "lastname",
      },
      {
        label: "نام",
        key: "firstname",
      },
      {
        label: "شمارنده",
        key: "id",
      },
    ],
    data: accessbileUserData.content.map((item, index) => ({
      firstname: item.firstname,
      lastname: item.lastname,
      username: item.username,
      email: item.email,
      id:
        accessbileUserData.pageNumber * accessbileUserData.pageSize +
        (index + 1) -
        accessbileUserData.pageSize,
    })),
    filename: "users.csv",
  };

  return (
    <div className={`${styles.usersContaienr} `}>
      <>
        {window.innerWidth > 1000 ? null : (
          <div className="w-100 d-flex flex-row-reverse align-items-center justify-content-between">
            <p
              style={{
                color: "#111D64",
                fontSize: "1.5rem",
                fontWeight: "bold",
              }}
            >
              لیست کاربران
            </p>

            <button
              className={`operationEvent ${
                isSearchToolsOpen ? "submitBtn" : "editBtn"
              }`}
              onClick={() => {
                setIsSearchToolsOpen((prevState) => !prevState);
              }}
            >
              جستجو
            </button>
          </div>
        )}
        {isSearchToolsOpen ? (
          <div
            className={`${styles.topItems} ${
              isNightOn ? "nightBg1" : ""
            }  w-100 d-flex flex-column align-items-end `}
          >
            {/*  */}
            {/*  */}
            {/*  */}
            {/*  */}
            {/*  */}

            {window.innerWidth < 1000 ? null : (
              <div
                className={`w-100 d-flex flex-row-reverse align-items-center justify-content-between ${styles.pageInfo}`}
              >
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                  onClick={() => {}}
                >
                  لیست کاربران
                </p>
                <div className="d-flex flex-col align-items-center">
                  {permitionCheckerWithoutToast(
                    allGroups,
                    [
                      "Full_Manager",
                      "Tenant_Full_Manager",
                      "Tenant_Basic_Manager",
                      "User_Editor",
                    ],
                    isFullRenderd
                  ) && (
                    <Link
                      to={"/users/add-user"}
                      style={{
                        alignSelf: "flex-start",
                        padding: "6px 10px",
                        fontSize: "14px",
                        borderRadius: "8px",
                        background: themeColor,
                        color: "#fff",
                        cursor: "pointer",
                      }}
                      className="d-flex align-items-center"
                    >
                      افزودن کاربر
                      <span
                        className="icon-Plus"
                        style={{ marginLeft: "6px" }}
                      ></span>
                    </Link>
                  )}

                  <CSVLink
                    {...csvReport}
                    style={{
                      textDecoration: "none",
                      color: "#fff",
                      background: "#3AC150",
                      marginBottom: "1rem",
                      padding: "6px 10px",
                      marginLeft: "8px",
                      borderRadius: "8px",
                    }}
                  >
                    csv خروجی
                  </CSVLink>
                </div>
              </div>
            )}

            {/*  */}
            {/*  */}
            {/*  */}
            {/*  */}
            {/*  */}

            <div
              className={`w-100 d-flex flex-row align-items-center justify-content-between flex-wrap flex-row-reverse`}
              style={{
                gap: window.innerWidth < 1000 ? "8px" : "",
              }}
            >
              <CustomInput1
                placeHolder=" جستجو  بر اساس هر مقداری"
                //    keyForShowOnTop="جستوجو بر اساس هر مقداری"
                defaultValue={""}
                inputType="text"
                value={fetchUserOptions.search}
                onBlur={(e) => {
                  if (e.target.value === fetchUserOptions.search) return;
                  setFetchingUserOptions((prevState) => ({
                    ...prevState,
                    search: e.target.value,
                    page: 1,
                  }));
                  setSearchParam({
                    page: "1",
                    size: fetchUserOptions.size + "",
                  });
                }}
                onChange={(e) => {
                  if (e.target.value === fetchUserOptions.search) return;
                  setFetchingUserOptions((prevState) => ({
                    ...prevState,
                    search: e.target.value,
                    page: 1,
                  }));
                  setSearchParam({
                    page: "1",
                    size: fetchUserOptions.size + "",
                  });
                }}
                onKeyPress={(e) => {
                  if (window.innerWidth < 1000) return;
                  if (e.key !== "Enter") return;
                  if (e.target.value === fetchUserOptions.search) return;
                  setFetchingUserOptions((prevState) => ({
                    ...prevState,
                    search: e.target.value,
                    page: 1,
                  }));
                  setSearchParam({
                    page: "1",
                    size: fetchUserOptions.size + "",
                  });
                }}
                containerStyle={{
                  width: "31%",
                }}
                keyStyle={{
                  color: "#8392AB",
                }}
              />
              {permitionCheckerWithoutToast(
                allGroups,
                permitions.canSeeTenantTextBox,
                isFullRenderd
              ) && (
                <CustomInput1
                  placeHolder=" جستجو  بر اساس نام سازمان"
                  //   keyForShowOnTop="جستوجو بر اساس نام سازمان"
                  value={selectedTenant.tenantTitle}
                  defaultValue={selectedTenant.tenantTitle}
                  inputType="text"
                  onBlur={(e) => {
                    return false;
                  }}
                  onChange={() => {
                    return false;
                  }}
                  containerStyle={{
                    width: "31%",
                  }}
                  keyStyle={{
                    color: "#8392AB",
                  }}
                  dontShowSearchIcon
                  disabled
                />
              )}

              <CustomSelectOption2
                getSelectedOption={(data) => {
                  if (String(data) === fetchUserOptions.group) return;
                  setFetchingUserOptions((prevState) => ({
                    ...prevState,
                    group: String(data),
                  }));
                }}
                initialTitle={(() => {
                  const userGroups = JSON.parse(
                    localStorage.getItem("groupsWithTitles")!
                  );
                  if (userGroups.length === 0) return "در حال بارگذاری";
                  let res = "گروه مورد نظر را انتخاب کنید";
                  for (let i = 0; i <= userGroups.length - 1; i++) {
                    if (userGroups[i].value === fetchUserOptions.group) {
                      res = userGroups[i].title;
                    }
                  }
                  return res;
                })()}
                initialValue={(() => {
                  const userGroups = JSON.parse(
                    localStorage.getItem("groupsWithTitles")!
                  );
                  if (userGroups.length === 0) return "در حال بارگذاری";
                  let res = "گروه مورد نظر را انتخاب کنید";
                  for (let i = 0; i <= userGroups.length - 1; i++) {
                    if (userGroups[i].value === fetchUserOptions.group) {
                      res = userGroups[i].value;
                    }
                  }
                  return res;
                })()}
                options={allGroupsWithTitles.map((item) => ({
                  title: item.title,
                  value: item.value,
                }))}
              />
            </div>

            <MultipleSelect
              title="جستجوی پیشرفته (چند اسمی)"
              containerClassName="mt-2"
              _setSelectedItems={(data) => {
                setFetchingUserOptions((prevState) => ({
                  ...prevState,
                  page: 1,
                }));
                setFetchingUserOptions((prevState) => ({
                  ...prevState,
                  usernames: data,
                }));
              }}
              _selectedItems={fetchUserOptions.usernames}
            />
          </div>
        ) : null}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        <div
          className={`${
            styles.dataContainer
          } d-flex flex-column align-items-center ${
            isNightOn ? "nightBg1" : ""
          } w-100`}
        >
          {window.innerWidth > 1000 ? (
            <>
              {accessbileUserData.isPending ? (
                <div className={`${styles.loadingContainer} mt-4`}>
                  <p>در حال بارگذاری</p>
                  <Spinner />
                </div>
              ) : (
                <>
                  {accessbileUserData.content.length === 0 ? (
                    <div className="w-100 d-flex align-items-center justify-content-center">
                      موردی وجود ندارد
                    </div>
                  ) : (
                    <div
                      className={`${styles.myTable} ${
                        isNightOn ? "nightBg2 pcNight" : ""
                      } w-100 d-flex flex-column align-items-center align-items-center`}
                    >
                      <div
                        className={`${styles.tableHead}  w-100 d-flex align-items-center justify-content-between align-items-center`}
                      >
                        <p className={`${styles.operationTab}`}>عملیات</p>
                        <p className={`${styles.emailTab}`}>ایمیل</p>
                        <p className={`${styles.userNameTab}`}>نام کاربری</p>
                        <p className={`${styles.detailsTab}`}>
                          نام و نام خانوادگی
                        </p>
                      </div>
                      <div
                        className={`${styles.tableBody} w-100 d-flex align-items-center flex-column`}
                      >
                        {accessbileUserData.content.map((item) => (
                          <div
                            key={item.email}
                            className={`${styles.tableData} w-100 d-flex align-items-center justify-content-between align-items-center`}
                          >
                            <p className={`${styles.operationTab}`}>
                              {permitionCheckerWithoutToast(
                                allGroups,
                                [
                                  "Full_Manager",
                                  "Tenant_Full_Manager",
                                  "Tenant_Basic_Manager",
                                  "User_Editor",
                                ],
                                isFullRenderd
                              ) && (
                                <Link
                                  to={`/users/single-user/${item.username}`}
                                  className="operationEvent editBtn"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  <span
                                    className="icon-edit"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="ویرایش"
                                  ></span>
                                </Link>
                              )}
                            </p>
                            <p
                              className={`${styles.emailTab}`}
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={
                                item.email.length >= 25 ? item.email : ""
                              }
                            >
                              {item.email.length >= 25
                                ? "..." + item.email.substring(0, 24)
                                : item.email}
                            </p>
                            <p
                              className={`${styles.userNameTab}`}
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={
                                item.username.length >= 24 ? item.username : ""
                              }
                            >
                              {item.username.length >= 24
                                ? "..." + item.username.substring(0, 23)
                                : item.username}
                            </p>
                            <p
                              className={`${styles.detailsTab}`}
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={
                                String(item.firstname + item.lastname).length >=
                                25
                                  ? String(item.firstname + " " + item.lastname)
                                  : ""
                              }
                            >
                              {String(item.firstname + item.lastname).length >=
                              25
                                ? "..." +
                                  String(
                                    item.firstname + item.lastname
                                  ).substring(0, 24)
                                : String(item.firstname + " " + item.lastname)}
                            </p>
                          </div>
                        ))}
                      </div>
                      <div className={`${styles.tableFooter}`}></div>
                    </div>
                  )}
                </>
              )}
            </>
          ) : (
            <div
              className={`${styles.cards} w-100 d-flex flex-column align-items-center`}
            >
              {accessbileUserData.isPending ? (
                <div className={`${styles.loadingContainer} mt-4`}>
                  <p>در حال بارگذاری</p>
                  <Spinner />
                </div>
              ) : (
                <>
                  {accessbileUserData.content.length === 0 ? (
                    <div className={`${styles.loadingContainer} mt-4`}>
                      <p>موردی وجود ندارد</p>
                    </div>
                  ) : (
                    <>
                      {accessbileUserData.content.map((item) => (
                        <div key={item.email} className="w-100">
                          <div
                            className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between `}
                          >
                            <p>{item.email}</p>
                            <p>کاربر </p>
                          </div>
                          <div
                            className={`${styles.tableRow} ${
                              isNightOn ? "nightBg2" : ""
                            } w-100 d-flex align-items-center justify-content-between `}
                          >
                            <p>
                              <span>نام</span>
                              <span>{item.username}</span>
                            </p>
                            <p>
                              <span>نام خانوادگی</span>
                              <span>{item.lastname}</span>
                            </p>
                            <p>
                              <span>عملیات</span>
                              {permitionCheckerWithoutToast(
                                allGroups,
                                [
                                  "Full_Manager",
                                  "Tenant_Full_Manager",
                                  "Tenant_Basic_Manager",
                                  "User_Editor",
                                ],
                                isFullRenderd
                              ) && (
                                <Link
                                  to={`/users/single-user/${item.username}`}
                                  className="operationEvent editBtn"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  <span
                                    className="icon-edit"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="ویرایش"
                                  ></span>
                                </Link>
                              )}
                            </p>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </>
              )}
            </div>
          )}
          <Pagination
            dataForPagination={{
              currPage: fetchUserOptions.page,
              size: fetchUserOptions.size,
              totalPages: accessbileUserData.totalPages,
            }}
            onPageNumberChange={(pageNumber) => {
              setFetchingUserOptions((prevState) => ({
                ...prevState,
                page: Number(pageNumber),
              }));
            }}
            onPageNumbersClick={(item: any) => {
              setFetchingUserOptions((prevState) => ({
                ...prevState,
                page: item,
              }));
            }}
            onPageSizeChange={(size) => {
              setFetchingUserOptions((prevState) => ({
                ...prevState,
                size: Number(size),
              }));
            }}
            search={fetchUserOptions.search}
          />
        </div>
      </>
      {window.innerWidth < 1000 ? (
        <div className={`${styles.absoluteBottomItems}`}>
          {permitionCheckerWithoutToast(
            allGroups,
            [
              "Full_Manager",
              "Tenant_Full_Manager",
              "Tenant_Basic_Manager",
              "User_Editor",
            ],
            isFullRenderd
          ) && (
            <Link
              to={"/users/add-user"}
              style={{
                padding: "6px 10px",
                fontSize: "14px",
                borderRadius: "8px",
                background: themeColor,
                color: "#fff",
                cursor: "pointer",
              }}
              className="d-flex align-items-center"
            >
              افزودن کاربر
              <span className="icon-Plus" style={{ marginLeft: "6px" }}></span>
            </Link>
          )}
          <CSVLink
            {...csvReport}
            style={{
              textDecoration: "none",
              color: "#fff",
              background: "#3AC150",
              marginBottom: "1rem",
              padding: "6px 10px",
              marginLeft: "8px",
              borderRadius: "8px",
            }}
          >
            csv خروجی
          </CSVLink>
        </div>
      ) : null}
    </div>
  );
};

export default Users;
