// Axios
import { AxiosResponse } from "axios";
import { _axios_withoutHeaderToken } from "../../Base_Axios_Config/_axios_withoutHeaderToken";
// Axios

// Models
import {
  I_Message,
  I_RecevedDataFromAllocatedProcessStartingFormRequest,
} from "../../../Models/interfaces";
// Models

export const getSingleAllocatedProcessStartingFormService = async (_data: {
  userToken: string;
  _data: {
    processKey: string | number;
  };
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: I_RecevedDataFromAllocatedProcessStartingFormRequest;
  }>
> => {
  return _axios_withoutHeaderToken.get(
    `/allocated-processes/${_data._data.processKey}/starting-form`
    // {
    //   headers: {
    //     Authorization: `Bearer ${_data.userToken}`,
    //   },
    // }
  );
};
