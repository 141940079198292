// Types
import { LanguageType } from "../Models/types";
// Types

export const language: {
  [key in LanguageType]: {
    [key: string]: string;
  };
} = {
  en: {
    home: "home",
    login: "login",
    userName: "userName",
    password: "password",
    userNamelengthError: "error in userName length",
    loginSuccess: "loginSuccess",
    userFetchDataFailed: "userFetchDataFailed",
    userFetchDataSuccess: "userFetchDataSuccess",
    userEditDataSuccess: "userEditDataSuccess",
    noPermition: "noPermition , Ask For permitions",
    addProcess: "add Process",
    processList: "process List",
    sendDataToServer: "sendDataToServer",
    userDataFetching: "userDataFetching",
    runningInstances: "Running Instances",
    start: "Start",
    forms: "Forms",
    seeDetails: "See Details",
    seeTasks: "See Tasks",
    edit: "Edit",
    delete: "Delete",
    viewReports: "View Reports",
    refreshToken: "Refresh Token",
    more: "More",
    showTask: "See Tasks",
    see: "see",
    xml: "xml",
    add: "add",
    identities: "identities",
    request: "request",
    json: "json",
    changeStatus: "changeStatus",
    copy: "copy",
    thisWillAddedOnBottomOfForm: "this will added on bottom of Form",
    online: `You're Online`,
    offline: `You're Offline`,
    back: "back",
    exit: "exit",
    profile: "profile",
    goToNightMode: "goToNightMode",
    exitFromNightMode: "exit From Night Mode",
    helpCenter: "help center",
    processFile: "processFile",
    showProcess: "showProcess",
    deleteVersion: "deleteVersion",
  },
  fa: {
    home: "خانه",
    login: "ورود",
    userName: "نام کاربری",
    password: "رمز عبور",
    userNamelengthError: "طول نام کاربری از حد مجاز کمتر است",
    loginSuccess: "با موفقیت وارد شدید ",
    userFetchDataFailed: "خطا در دریافت اطلاعات کاربر",
    userFetchDataSuccess: "اطلاعات کاربر با موفقیت دریافت شد",
    userEditDataSuccess: "اطلاعات کاربر با موفقیت تغییر یافت",
    noPermition: "شما به این قسمت دسترسی ندارید",
    addProcess: "افزودن فرآیند",
    processList: "لیست فرآیند ها",
    sendDataToServer: "ارسل موارد به سرور",
    userDataFetching: "در حال دریافت اطلاعات",
    runningInstances: "اجرا ها",
    start: "شروع",
    forms: "فرم ها",
    seeDetails: "نمایش جزئیات",
    seeTasks: "نمایش وظایف",
    edit: "ویرایش",
    delete: "حذف",
    viewReports: "گزارشات",
    refreshToken: "توکن جدید",
    more: "بیشتر",
    showTask: "مشاهده وظیفه ",
    see: "مشاهده",
    xml: "xml",
    add: "افزودن",
    identities: "شناسه ها",
    request: "درخواست",
    json: "json",
    changeStatus: "تغییر وضعیت",
    copy: "رونوشت",
    thisWillAddedOnBottomOfForm: "این فیلد در انتهای فرم فعلی اضافه میگردد",
    exit: "خروج",
    back: "بازگشت",
    profile: "پروفایل",
    goToNightMode: "رفتن به حالت شب",
    exitFromNightMode: "رفتن به حالت روز",
    helpCenter: "پشتیبانی",
    processFile: "فایل خروجی",
    showProcess: "نمایش فرآیند",
    deleteVersion: "حذف نسخه ی خاص",
  },
};
