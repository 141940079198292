// React
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  // useEffect,
  useState,
} from "react";
// React

// CSS
import styles from "./ViewRunningInstances.module.css";
// CSS

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  getAsyncInstanceProcessXmlData,
  getAsyncRunningInstancesOfSingleProcess,
  processesSlice,
  removeAsyncSingleRunningProcessInstance,
} from "../../../Features/ProcessesSlice/ProcessesSlice";
import { searchAndBackSlice } from "../../../Features/SearchAndBackSlice/searchAndBackSlice";
// Redux

// Modules
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
// Modules

// Utils
import { Spinner } from "reactstrap";
import Pagination from "../../../Utils/Pagination/Pagination";
import { CSVLink } from "react-csv";
import { customizedToast } from "../../../Utils/CustomizedToast/customizedToast";
import { downloadAsXml } from "../../../Utils/DownloadUtils/downloadAsXml";
import { downloadAsBpmnFile } from "../../../Utils/DownloadUtils/downloadAsBpmnFile";
// Utils

// Icons
import { BsEye } from "react-icons/bs";
// Icons

// Components
// Components

// Contexts
import { UserGroupsContext } from "../../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsFullRenderdContext } from "../../../Contexts/UserGroupsContext/IsFullRenderdContext";
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Constants
import { downloadProcessNamePreFix } from "../../../Constants/constants";
import { I_SendingRunningInstanceData } from "../../../Models/sendingDataSchema";
import CustomInput1 from "../../../Utils/CustomInput1/CustomInput1";
import CustomCheckbox from "../../../Utils/CustomCheckbox/CustomCheckbox";
import { permitionCheckerWithoutToast } from "../../../Modules/permitionCheckerWithoutToast";
import Swal from "sweetalert2";
// Constants

const ViewRunningInstances = () => {
  const { processKey, presianProcessName } = useParams();
  const dispatch = useAppDispatch();
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const allGroups = useContext(UserGroupsContext);
  const isFullRenderd = useContext(IsFullRenderdContext);

  const navigate = useNavigate();

  const { processInstanceData } = useAppSelector((state) => state.processes);

  const selectedTenant = useAppSelector(
    (state) => state.tenants.selectedTenant
  );

  const [searchParam, setSearchParam] = useSearchParams();

  const { language } = useAppSelector((state) => state.currLanguage);

  const [formsData, setFormsData] = useState<
    I_SendingRunningInstanceData & {
      processName: string;
    }
  >({
    page: Number(searchParam.get("page")) || 1,
    size: Number(searchParam.get("size")) || 10,
    tenant: selectedTenant.tenantId,
    processKey: String(processKey),
    processName: String(presianProcessName),
    activeInstancesOnly: Boolean(
      searchParam.get("activeInstancesOnly") === "true"
    ),
    rootInstancesOnly: Boolean(searchParam.get("rootInstancesOnly") === "true"),
    search: searchParam.get("search") || "",
  });

  const isNightOn = useContext(IsNightModeOnContext);

  const canShowRemoveInstanceCol = useMemo(() => {
    if (
      !permitionCheckerWithoutToast(
        allGroups,
        [
          "Tenant_Full_Manager",
          "Tenant_Basic_Manager",
          "Process_Full_Manager",
          "Running_Process_Manager",
        ],
        isFullRenderd
      )
    )
      return false;

    return true;
  }, [allGroups, isFullRenderd]);

  const dataGetter = useCallback(() => {
    dispatch(
      getAsyncRunningInstancesOfSingleProcess({
        _data: formsData,
        userToken,
      })
    );
  }, [dispatch, formsData, userToken]);

  useEffect(() => {
    dispatch(searchAndBackSlice.actions.changeCurrLocation("اجراها"));
  }, [dispatch]);

  useEffect(() => {
    const timeOut = setTimeout(dataGetter, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [dataGetter]);

  useEffect(() => {
    return () => {
      dispatch(processesSlice.actions.resetReceivedProcessInstancesData());
    };
  }, [dispatch]);

  useEffect(() => {
    setSearchParam(
      {
        page: String(formsData.page) || 1 + "",
        size: String(formsData.size) || 10 + "",
        activeInstancesOnly: String(formsData.activeInstancesOnly),
        rootInstancesOnly: String(formsData.rootInstancesOnly),
        search: String(formsData.search),
      },
      {
        replace: true,
      }
    );
  }, [formsData, setSearchParam, searchParam]);

  const csvReport = {
    headers: [
      {
        label: "شمارنده",
        key: "instanceId",
      },
      {
        label: "نام فرآیند",
        key: "title",
      },
      {
        label: "کدفرایند",
        key: "businessKey",
      },
      {
        label: "شمارنده فرآیند بالادست",
        key: "processId",
      },
      {
        label: "کلید فرآیند بالادست",
        key: "processKey",
      },
      {
        label: "کلید استقرار",
        key: "deploymentId",
      },
      {
        label: "قابل شروع ",
        key: "startable",
      },
      {
        label: "قابل شروع ",
        key: "startable",
      },
      {
        label: "دارای فرم شروع",
        key: "hasStartingForm",
      },
      {
        label: "آدرس فایل",
        key: "processFileName",
      },
    ],
    data: processInstanceData.content.map((item, index) => ({
      instanceId: item.instanceId,
      businessKey: item.businessKey,
      title: item.title,
      processId: item?.process?.processId,
      processKey: item?.process?.processKey,
      deploymentId: item?.process?.deploymentId,
      processName: item?.process?.processName,
      processFileName: item?.process?.processFileName,
      startable: item?.process?.startable,
      hasStartingForm: item?.process?.hasStartingForm,
    })),
    filename: `instanceData__${processKey}.csv`,
  };

  return (
    <div
      className={`${styles.runningInstancesContainer} ${
        isNightOn ? "nightBg1" : ""
      } w-100`}
    >
      <div
        className={`${styles.topItems} w-100 d-flex flex-row-reverse align-items-center justify-content-between`}
      >
        <p>اجرا های فرآیند : {presianProcessName}</p>
        <CSVLink {...csvReport} className={"operationEvent csvBtn"}>
          csv فایل
        </CSVLink>
      </div>

      {/* <div
        className={`${styles.rows} w-100 d-flex align-items-center justify-content-between flex-wrap`}
      >
        {permitionCheckerWithoutToast(
          allGroups,
          permitions.canSeeTenantTextBox,
          isFullRenderd
        ) && (
          <CustomInput1
            value={selectedTenant.tenantTitle}
            defaultValue={selectedTenant.tenantTitle}
            inputType="text"
            onBlur={(e) => {
              return false;
            }}
            onChange={() => {
              return false;
            }}
            placeHolder="نام سازمان"
            //    keyForShowOnTop="نام سازمان"
            dontShowSearchIcon
            disabled
          />
        )}
        <CustomInput1
          defaultValue={formsData.processName}
          inputType="text"
          onBlur={(e) => {
            return false;
          }}
          onChange={() => {
            return false;
          }}
          placeHolder="نام فرآیند"
          //  keyForShowOnTop="نام فرآیند"
          value={formsData.processName}
        />
      </div> */}

      <div className="w-100 d-flex flex-row-reverse mt-2 align-items-center justify-content-between flex-wrap">
        <CustomInput1
          defaultValue={formsData.search}
          inputType="text"
          onBlur={() => {}}
          onChange={(e) => {
            setFormsData((prevState) => ({
              ...prevState,
              search: e.target.value,
            }));
          }}
          placeHolder="جستجو"
          value={formsData.search}
        />
        <CustomCheckbox
          isChecked={formsData.activeInstancesOnly}
          onChange={(e) => {}}
          onClick={() => {
            setFormsData((prevState) => ({
              ...prevState,
              activeInstancesOnly: !prevState.activeInstancesOnly,
            }));
          }}
          title="نمونه های فعال"
          containerClassName={`  d-flex justify-between ${
            window.innerWidth > 1000 ? "" : "w-100 mt-4"
          }`}
          titleClassName={"mb-0"}
        />
        <CustomCheckbox
          isChecked={formsData.rootInstancesOnly}
          onChange={() => {}}
          onClick={() => {
            setFormsData((prevState) => ({
              ...prevState,
              rootInstancesOnly: !prevState.rootInstancesOnly,
            }));
          }}
          title="نمونه های اصلی"
          containerClassName={`  d-flex justify-between ${
            window.innerWidth > 1000 ? "" : "w-100 mt-4"
          }`}
          titleClassName={"mb-0"}
        />
      </div>

      {processInstanceData.isProcessInstancePending ? (
        <div className="w-100 d-flex flex-column align-items-center mt-2">
          <p>در حال بارگیری</p>
          <Spinner />
        </div>
      ) : (
        <>
          {processInstanceData.content.length === 0 ? (
            <div className="w-100 d-flex align-items-center justify-content-center">
              موردی وجود ندارد
            </div>
          ) : (
            <>
              {window.innerWidth > 1000 ? (
                <div
                  className={`${styles.myTable} ${
                    isNightOn ? "nightBg2 pcNight" : ""
                  } w-100 d-flex flex-column align-items-center align-items-center`}
                >
                  <div
                    className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between align-items-center`}
                  >
                    <p className={`${styles.operationTab}`}>عملیات</p>
                    {canShowRemoveInstanceCol && (
                      <p
                        className={`${styles.removeInstance} d-flex align-items-center justify-content-center`}
                      >
                        حذف اجرا
                      </p>
                    )}
                    <p className={`${styles.hastStartingForm}`}>
                      دارای فرم اولیه
                    </p>
                    <p className={`${styles.processStatus}`}>وضعیت فرآیند</p>
                    <p className={`${styles.startable} `}>قابل شروع</p>
                    <p className={`${styles.processKey}`}>کد اصلی فرآیند</p>
                    <p className={`${styles.title}`}>عنوان</p>
                  </div>
                  <div
                    className={`${styles.tableBody} w-100 d-flex align-items-center flex-column`}
                  >
                    {processInstanceData.content.map(
                      ({ businessKey, instanceId, process, title, active }) => (
                        <div
                          key={instanceId}
                          className={`${styles.tableData} w-100 d-flex align-items-center justify-content-between align-items-center`}
                        >
                          <p className={`${styles.operationTab}`}>
                            {permitionCheckerWithoutToast(
                              allGroups,
                              [
                                "Tenant_Full_Manager",
                                "Tenant_Basic_Manager",
                                "Process_Full_Manager",
                                "Running_Process_Manager",
                              ],
                              isFullRenderd
                            ) ||
                            (allGroups.includes("Running_Process_Manager") &&
                              allGroups.includes("Process_Schema_Analyzer")) ? (
                              <button
                                className={` operationEvent xmlBtn`}
                                onClick={() => {
                                  if (
                                    !permitionCheckerWithoutToast(
                                      allGroups,
                                      [
                                        "Tenant_Full_Manager",
                                        "Tenant_Basic_Manager",
                                        "Process_Full_Manager",
                                        "Running_Process_Manager",
                                      ],
                                      isFullRenderd
                                    ) ||
                                    !(
                                      allGroups.includes(
                                        "Running_Process_Manager"
                                      ) &&
                                      allGroups.includes(
                                        "Process_Schema_Analyzer"
                                      )
                                    )
                                  )
                                    return;
                                  customizedToast(
                                    "در خواست دانلود ارسال شد",
                                    "info"
                                  );
                                  dispatch(
                                    getAsyncInstanceProcessXmlData({
                                      instanceId: instanceId,
                                      userToken,
                                      onDone: (data) => {
                                        downloadAsXml(
                                          [data],
                                          `${downloadProcessNamePreFix}${processKey}`
                                        );
                                        downloadAsBpmnFile(
                                          [data],
                                          `${downloadProcessNamePreFix}${processKey}`
                                        );
                                      },
                                    })
                                  ).then(() => {
                                    dispatch(
                                      processesSlice.actions.resetProcessInstanceXmlData()
                                    );
                                  });
                                }}
                              >
                                <span
                                  className="icon-document-bpmn"
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content={language.processFile}
                                ></span>
                              </button>
                            ) : null}
                            {/*  */}
                            {/*  */}
                            {/*  */}
                            {/*  */}
                            <button
                              className="operationEvent showMoreBtn"
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={language.showProcess}
                              onClick={() => {
                                navigate(
                                  `/processes/show-instance-data-by-instance-id/${instanceId}`
                                );
                              }}
                            >
                              <BsEye />
                            </button>
                          </p>
                          {canShowRemoveInstanceCol && (
                            <p
                              className={`${styles.removeInstance} d-flex align-items-center justify-content-center`}
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={"حذف اجرای فعلی"}
                              onClick={() => {
                                Swal.fire({
                                  icon: "question",
                                  title: `اجرای ${title} حذف شود ؟`,
                                  text: "عملیات غیر قابل بازگشت میباشد",
                                  showCancelButton: true,
                                  cancelButtonText: "لغو",
                                  confirmButtonText: "تایید",
                                  customClass: isNightOn ? "swalNight" : "",
                                }).then(({ isConfirmed }) => {
                                  if (!isConfirmed) return;
                                  dispatch(
                                    removeAsyncSingleRunningProcessInstance({
                                      userToken,
                                      _data: {
                                        processInstanceId: instanceId,
                                      },
                                      onDone: dataGetter,
                                    })
                                  );
                                });
                              }}
                            >
                              <span className="icon-trash"></span>
                            </p>
                          )}
                          <p className={`${styles.hastStartingForm}`}>
                            {process.hasStartingForm ? "بله" : "خیر"}
                          </p>
                          <p className={`${styles.processStatus}`}>
                            {active ? (
                              <span className="bg-green px-2 rounded-2 text-white">
                                در حال اجرا
                              </span>
                            ) : (
                              <span className="bg-gray px-2 rounded-2 text-white">
                                خاتمه یافته
                              </span>
                            )}
                          </p>
                          <p className={`${styles.startable}`}>
                            {process.startable ? "بله" : "خیر"}
                          </p>

                          <p
                            className={`${styles.processKey} truncate`}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={process.processKey}
                          >
                            {process.processKey}
                          </p>
                          <p className={`${styles.title} truncate`}>{title}</p>
                        </div>
                      )
                    )}
                  </div>
                  <div className={`${styles.tableFooter}`}></div>
                </div>
              ) : (
                <div
                  className={`${styles.cards} mt-4 w-100 d-flex flex-column align-items-center`}
                >
                  {processInstanceData.content.map((item) => (
                    <div key={item.instanceId} className="w-100">
                      <div
                        className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between `}
                      >
                        <p>{item.title}</p>
                        <p>نام</p>
                      </div>
                      <div
                        className={`${styles.tableRow} ${
                          isNightOn ? "nightBg2" : ""
                        } w-100 d-flex align-items-center justify-content-between `}
                      >
                        <p>
                          <span>شمارنده اجرا</span>
                          <span>{item.instanceId}</span>
                        </p>
                        <p>
                          <span>کد کاری</span>
                          <span>{item.businessKey}</span>
                        </p>
                        <p>
                          <span>عملیات</span>

                          {permitionCheckerWithoutToast(
                            allGroups,
                            [
                              "Tenant_Full_Manager",
                              "Tenant_Basic_Manager",
                              "Process_Full_Manager",
                            ],
                            isFullRenderd
                          ) ||
                          (allGroups.includes("Running_Process_Manager") &&
                            allGroups.includes("Process_Schema_Analyzer")) ? (
                            <button
                              className={` operationEvent xmlBtn`}
                              onClick={() => {
                                if (
                                  !permitionCheckerWithoutToast(
                                    allGroups,
                                    [
                                      "Tenant_Full_Manager",
                                      "Tenant_Basic_Manager",
                                      "Process_Full_Manager",
                                    ],
                                    isFullRenderd
                                  ) ||
                                  !(
                                    allGroups.includes(
                                      "Running_Process_Manager"
                                    ) &&
                                    allGroups.includes(
                                      "Process_Schema_Analyzer"
                                    )
                                  )
                                )
                                  return;
                                customizedToast(
                                  "در خواست دانلود ارسال شد",
                                  "info"
                                );

                                dispatch(
                                  getAsyncInstanceProcessXmlData({
                                    instanceId: item.instanceId,
                                    userToken,
                                    onDone: (data) => {
                                      downloadAsXml(
                                        [data],
                                        `${downloadProcessNamePreFix}${processKey}`
                                      );
                                      downloadAsBpmnFile(
                                        [data],
                                        `${downloadProcessNamePreFix}${processKey}`
                                      );
                                    },
                                  })
                                ).then(() => {
                                  dispatch(
                                    processesSlice.actions.resetProcessInstanceXmlData()
                                  );
                                });
                              }}
                            >
                              <span
                                className="icon-document-bpmn"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={language.processFile}
                              ></span>
                            </button>
                          ) : null}
                          {/*  */}
                          {/*  */}
                          {/*  */}
                          {/*  */}
                          <button
                            className="operationEvent showMoreBtn"
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={language.showProcess}
                            onClick={() => {
                              navigate(
                                `/processes/show-instance-data-by-instance-id/${item.instanceId}`
                              );
                            }}
                          >
                            <BsEye />
                          </button>
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}

          <Pagination
            dataForPagination={{
              currPage: formsData.page,
              size: formsData.size,
              totalPages: processInstanceData.totalPages,
            }}
            onPageNumberChange={(pageNumber) => {
              setFormsData((prevState) => ({
                ...prevState,
                page: Number(pageNumber),
              }));
            }}
            onPageNumbersClick={(page: number) => {
              setFormsData((prevState) => ({
                ...prevState,
                page,
              }));
            }}
            onPageSizeChange={(size) => {
              setFormsData((prevState) => ({
                ...prevState,
                size: Number(size),
              }));
            }}
            search={""}
          />
        </>
      )}
    </div>
  );
};

export default ViewRunningInstances;
