// React
import React, { useEffect, useState } from "react";
// React

// CSS
import styles from "./HomePageAllocatedProcesses.module.css";
// CSS

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Keyboard, Mousewheel, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// Swiper

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { allocatedProcessesSlice } from "../../../Features/AllocatedProcessesSlice/allocatedProcessesSlice";
import { searchAndBackSlice } from "../../../Features/SearchAndBackSlice/searchAndBackSlice";
// Redux

// Modals
import StartAllocatedProcessModal from "../../AllocatedProcesses/StartAllocatedProcessModal/StartAllocatedProcessModal";
import { I_AllocatedProcessItem } from "../../../Models/interfaces";
// Modals

// Components
import AllocatedCard from "./AllocatedCard/AllocatedCard";
// Components

// Utils
import { Spinner } from "reactstrap";
// Utils

type HomePageAllocatedProcessesProps = {
  containerClassName: string;
};

const HomePageAllocatedProcesses: React.FunctionComponent<
  HomePageAllocatedProcessesProps
> = ({ containerClassName }) => {
  const dispatch = useAppDispatch();

  const tasksData = useAppSelector((state) => state.tasks.taskData);

  const [isModalInScreen, setIsModalInScreen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<{
    processKey: string;
    processId: string;
    persianProcessName: string;
  }>({
    processId: "",
    processKey: "",
    persianProcessName: "",
  });

  const { data, isPending } = useAppSelector(
    (state) => state.allocatedProcesses.allocatedProcessData
  );

  useEffect(() => {
    dispatch(
      searchAndBackSlice.actions.setAllocatedProcessesData({
        pageNumber: 1,
        size: 999,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(allocatedProcessesSlice.actions.resetAllocatedProcessesState());
    };
  }, [dispatch]);

  return (
    <div
      className={`${styles.homePageAllocatedProcessesContainer}
        ${containerClassName}
         `}
    >
      {isPending ? (
        <div className="w-100 d-flex flex-column align-items-center mt-2 justify-content-center">
          <p>در حال بارگیری</p>
          <Spinner />
        </div>
      ) : (
        <>
          <p className="w-100 d-flex align-items-center justify-content-end mb-4 bold">
            رویه های کاری
          </p>
          {tasksData.content.length === 0 ? (
            <div className={styles.allocatedGrid}>
              {data.content.map((item: I_AllocatedProcessItem) => (
                <AllocatedCard
                  item={item}
                  setIsModalInScreen={setIsModalInScreen}
                  setModalData={setModalData}
                  key={item.deploymentId}
                />
              ))}
            </div>
          ) : (
            <div className={`${styles.processContainer}  w-100`}>
              <Swiper
                spaceBetween={8}
                slidesPerView={3}
                initialSlide={0}
                className=" w-100"
                direction={"vertical"}
                speed={500}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                loop
                simulateTouch
                modules={[Mousewheel, Keyboard, Autoplay, Navigation]}
              >
                {data.content.map((item) => (
                  <SwiperSlide
                    key={item.deploymentId}
                    className={`${styles.processSlider}  w-100`}
                  >
                    <AllocatedCard
                      item={item}
                      setIsModalInScreen={setIsModalInScreen}
                      setModalData={setModalData}
                      key={item.deploymentId}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
        </>
      )}

      {isModalInScreen ? (
        <StartAllocatedProcessModal
          processKey={modalData.processKey}
          setIsModalInScreen={setIsModalInScreen}
          formData={{}}
          persianName={modalData.persianProcessName}
        />
      ) : null}
    </div>
  );
};

export default HomePageAllocatedProcesses;
