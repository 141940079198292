// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./Posts.module.css";
// CSS

// Redux
import { useAppDispatch, useAppSelector } from "../../app/store";
import {
  getAsyncAllPostsData,
  postsSlice,
  removeAsyncSinglePost,
} from "../../Features/PostsSlice/postsSlice";
// Redux

// Contexts
import { IsNightModeOnContext } from "../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Utils
import Pagination from "../../Utils/Pagination/Pagination";
import CustomInput1 from "../../Utils/CustomInput1/CustomInput1";
import Swal from "sweetalert2";
// Utils

// Modules
import { useNavigate, useSearchParams } from "react-router-dom";
// Modules

// Icons
import { MdDone } from "react-icons/md";
import { BsDashLg } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import { searchAndBackSlice } from "../../Features/SearchAndBackSlice/searchAndBackSlice";
import CustomSelectOption2 from "../../Utils/CustomSelectOption2/CustomSelectOption2";
import { permitionCheckerWithoutToast } from "../../Modules/permitionCheckerWithoutToast";
import { UserGroupsContext } from "../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsFullRenderdContext } from "../../Contexts/UserGroupsContext/IsFullRenderdContext";
import Pending from "../../Components/Pending/Pending";
// Icons

const Posts = () => {
  const dispatch = useAppDispatch();

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const { selectedTenant } = useAppSelector((state) => state.tenants);
  const { postsData } = useAppSelector((state) => state.posts);
  const { postPlacementTypes } = useAppSelector((state) => state.allRoles.data);

  const [searchParam, setSearchParam] = useSearchParams();

  const [isMounted, setIsMounted] = useState<boolean>(false);

  const [formData, setFormData] = useState<{
    page: number;
    size: number;
    placement: string;
    active: string | boolean;
    search: string;
  }>({
    page: Number(searchParam.get("page")) || 1,
    size: Number(searchParam.get("size")) || 10,
    placement: searchParam.get("placement") || "LOGIN_PAGE",
    active: Boolean(searchParam.get("active") === "true")
      ? "true"
      : Boolean(searchParam.get("active") === "false")
      ? "false"
      : "",
    search: searchParam.get("search") || "",
  });

  const isNightModeOn = useContext(IsNightModeOnContext);
  const allGroups = useContext(UserGroupsContext);
  const isFullRenderd = useContext(IsFullRenderdContext);

  const navigate = useNavigate();

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (!isMounted) return;
    const timeOut = setTimeout(() => {
      dispatch(
        getAsyncAllPostsData({
          userToken,
          _data: { ...formData, tenant: selectedTenant.tenantId },
        })
      );
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [formData, selectedTenant, userToken, dispatch, isMounted]);

  useEffect(() => {
    return () => {
      dispatch(postsSlice.actions.resetRecevedPostsDataState());
    };
  }, [dispatch]);

  useEffect(() => {
    setSearchParam(
      {
        page: String(formData.page) || 1 + "",
        size: String(formData.size) || 10 + "",
        placement: formData.placement,
        active: String(formData.active),
        search: formData.search,
      },
      {
        replace: true,
      }
    );
  }, [formData, setSearchParam, searchParam]);

  useEffect(() => {
    dispatch(
      searchAndBackSlice.actions.setPostsDataForPages({
        pageNumber: formData.page,
        size: formData.size,
      })
    );
  }, [dispatch, formData]);

  return (
    <div
      className={`${styles.postsContainer} ${isNightModeOn ? "nightBg1" : ""}`}
    >
      <div
        className={`${styles.postsTopContainer} w-100 d-flex flex-row align-items-center justify-content-between`}
      >
        {permitionCheckerWithoutToast(
          allGroups,

          ["Tenant_Full_Manager", "Tenant_Basic_Manager", "Post_Manager"],

          isFullRenderd
        ) ? (
          <button
            className="operationEvent submitBtn"
            onClick={() => {
              navigate(`/posts/add-new`);
            }}
          >
            <span className="icon-Plus"></span>
            <span>افزودن اعلان</span>
          </button>
        ) : (
          <span></span>
        )}
        <p
          style={{
            color: "#111D64",
            fontSize: "1.5rem",
            fontWeight: "bold",
          }}
        >
          لیست اعلانات
        </p>
      </div>
      <div
        className={`${styles.inputsContainer} my-4 w-100 d-flex flex-row-reverse align-items-center justify-content-between flex-wrap`}
        style={{
          gap: "8px",
        }}
      >
        <CustomSelectOption2
          getSelectedOption={(data) => {
            setFormData((prevState) => ({
              ...prevState,
              active: data === "true" ? true : data === "" ? "" : false,
            }));
          }}
          initialTitle={"همه"}
          initialValue={""}
          options={[
            {
              title: "همه",
              value: "",
            },
            {
              title: "فعال",
              value: "true",
            },
            {
              title: "غیر فعال",
              value: "false",
            },
          ]}
        />

        <CustomSelectOption2
          getSelectedOption={(data) => {
            setFormData((prevState) => ({
              ...prevState,
              placement: String(data),
            }));
          }}
          initialTitle={(() => {
            const placementTypes = JSON.parse(
              localStorage.getItem("basicItems")!
            )?.result?.postPlacementTypes;
            if (!placementTypes) return "در حال بارگذاری";

            const keys = Object.keys(placementTypes);
            let res = "محل قرارگیری";
            if (keys.length === 0) return "در حال بارگذاری";
            for (let i = 0; i <= keys.length - 1; i++) {
              if (placementTypes[keys[i]].value === formData.placement) {
                res = placementTypes[keys[i]].title;
              }
            }
            return res;
          })()}
          initialValue={"LOGIN_PAGE"}
          options={Object.keys(postPlacementTypes).map((_item) => ({
            title: postPlacementTypes[_item].title,
            value: postPlacementTypes[_item].value,
          }))}
        />

        <CustomInput1
          inputType="text"
          onBlur={() => {}}
          value={formData.search}
          defaultValue={formData.search}
          onChange={(e) => {
            setFormData((prevState) => ({
              ...prevState,
              search: e.target.value,
            }));
          }}
          placeHolder="جستجو"
        />
      </div>
      <div
        className={`${styles.dataContainer} ${
          isNightModeOn ? "nightBg1" : ""
        } w-100`}
      >
        {window.innerWidth > 1000 ? (
          <>
            {postsData.isPending ? (
              <Pending className="mt-4" />
            ) : (
              <>
                {postsData.data.content.length === 0 ? (
                  <div className="w-100 d-flex align-items-center justify-content-center">
                    موردی وجود ندارد
                  </div>
                ) : (
                  <div
                    className={`${styles.myTable} ${
                      isNightModeOn ? "nightBg2 pcNight" : ""
                    } w-100 d-flex flex-column align-items-center align-items-center`}
                  >
                    <div
                      className={`${styles.tableHead}  w-100 d-flex align-items-center justify-content-between align-items-center`}
                    >
                      <p className={`${styles.operationTab}`}>عملیات</p>
                      <p className={`${styles.isActive}`}>وضعیت</p>
                      <p className={`${styles.placement}`}>محل قرارگیری</p>
                      <p className={`${styles.id}`}>شمارنده</p>
                    </div>
                    <div
                      className={`${styles.tableBody} w-100 d-flex align-items-center flex-column`}
                    >
                      {postsData.data.content.map((item) => (
                        <div
                          key={item.id}
                          className={`${styles.tableData} w-100 d-flex align-items-center justify-content-between align-items-center`}
                        >
                          <p
                            className={`${styles.operationTab} flex-wrap d-flex flex-row align-items-center flex-wrap`}
                          >
                            <button
                              className="operationEvent deleteBtn"
                              onClick={() => {
                                Swal.fire({
                                  icon: "question",
                                  title: "آیا از حذف اعلان اطمینان دارید ؟",
                                  text: "عملیات غیر قابل بازگشت میباشد ",
                                  showCancelButton: true,
                                  cancelButtonText: "لغو",
                                  confirmButtonText: "حذف",
                                  customClass: isNightModeOn ? "swalNight" : "",
                                }).then(({ isConfirmed }) => {
                                  if (!isConfirmed) return;
                                  dispatch(
                                    removeAsyncSinglePost({
                                      userToken,
                                      _data: {
                                        postId: item.id,
                                      },
                                    })
                                  ).then(() => {
                                    dispatch(
                                      getAsyncAllPostsData({
                                        userToken,
                                        _data: {
                                          ...formData,
                                          tenant: selectedTenant.tenantId,
                                        },
                                      })
                                    );
                                  });
                                });
                              }}
                            >
                              <span className="icon-trash"></span>
                            </button>
                            <button
                              className="operationEvent editBtn"
                              onClick={() => {
                                navigate(
                                  `/posts/show-details-of-single-post?id=${
                                    item.id
                                  }&active=${item.active}&placement=${
                                    item.postPlacementType.title
                                  }&data=${JSON.stringify(item.data)}`
                                );
                              }}
                            >
                              <AiOutlineEye />
                            </button>
                            <button
                              className="operationEvent editBtn"
                              onClick={() => {
                                navigate(
                                  `/posts/edit-single-post?id=${
                                    item.id
                                  }&active=${item.active}&placement=${
                                    item.postPlacementType.value
                                  }&data=${JSON.stringify(item.data)}`
                                );
                              }}
                            >
                              <span className="icon-edit"></span>
                            </button>
                          </p>

                          <p className={`${styles.isActive}`}>
                            {item.active ? (
                              <MdDone
                                style={{
                                  fontSize: "2rem",
                                }}
                              />
                            ) : (
                              <BsDashLg
                                style={{
                                  fontSize: "2rem",
                                }}
                              />
                            )}
                          </p>
                          <p className={`${styles.placement}`}>
                            {item.postPlacementType.title}
                          </p>
                          <p className={`${styles.id}`}>{item.id}</p>
                        </div>
                      ))}
                    </div>
                    <div className={`${styles.tableFooter}`}></div>
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <div
            className={`${styles.cards} w-100 d-flex flex-column align-items-center`}
          >
            {postsData.data.content.length === 0 ? (
              <div className={`${styles.loadingContainer} mt-4`}>
                <p>موردی وجود ندارد</p>
              </div>
            ) : (
              <>
                {postsData.data.content.map((item) => (
                  <div key={item.id} className="w-100">
                    <div
                      className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between `}
                    >
                      <p>{item.active}</p>
                      <p>وضعیت</p>
                    </div>
                    <div
                      className={`${styles.tableRow} ${
                        isNightModeOn ? "nightBg2" : ""
                      } w-100 d-flex align-items-center justify-content-between `}
                    >
                      <p>
                        <span>محل قرارگیری</span>
                        <span>{item.postPlacementType.title}</span>
                      </p>
                      <p>
                        <span>عملیات</span>
                        <span className="d-flex w-100 align-items-center justify-content-center">
                          <button
                            className="operationEvent deleteBtn"
                            onClick={() => {
                              Swal.fire({
                                icon: "question",
                                title: "آیا از حذف اعلان اطمینان دارید ؟",
                                text: "عملیات غیر قابل بازگشت میباشد ",
                                showCancelButton: true,
                                cancelButtonText: "لغو",
                                confirmButtonText: "حذف",
                                customClass: isNightModeOn ? "swalNight" : "",
                              }).then(({ isConfirmed }) => {
                                if (!isConfirmed) return;
                                dispatch(
                                  removeAsyncSinglePost({
                                    userToken,
                                    _data: {
                                      postId: item.id,
                                    },
                                  })
                                ).then(() => {
                                  dispatch(
                                    getAsyncAllPostsData({
                                      userToken,
                                      _data: {
                                        ...formData,
                                        tenant: selectedTenant.tenantId,
                                      },
                                    })
                                  );
                                });
                              });
                            }}
                          >
                            <span className="icon-trash"></span>
                          </button>
                          <button
                            className="operationEvent viewBtn"
                            onClick={() => {
                              navigate(
                                `/posts/show-details-of-single-post?id=${
                                  item.id
                                }&active=${item.active}&placement=${
                                  item.postPlacementType.title
                                }&data=${JSON.stringify(item.data)}`
                              );
                            }}
                          >
                            <AiOutlineEye
                              style={{
                                fontSize: "1.5rem",
                              }}
                            />
                          </button>
                          <button
                            className="operationEvent editBtn"
                            onClick={() => {
                              navigate(
                                `/posts/edit-single-post?id=${item.id}&active=${
                                  item.active
                                }&placement=${
                                  item.postPlacementType.value
                                }&data=${JSON.stringify(item.data)}`
                              );
                            }}
                          >
                            <span className="icon-edit"></span>
                          </button>
                        </span>
                      </p>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        )}
      </div>

      <Pagination
        dataForPagination={{
          currPage: formData.page,
          size: formData.size,
          totalPages: postsData.data.totalPages,
        }}
        onPageNumberChange={(page) => {
          setFormData((prevState) => ({ ...prevState, page }));
        }}
        onPageNumbersClick={(page) => {
          setFormData((prevState) => ({ ...prevState, page }));
        }}
        onPageSizeChange={(size) => {
          setFormData((prevState) => ({ ...prevState, size }));
        }}
        search={formData.search}
      />
    </div>
  );
};

export default Posts;
