// React
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
// React

// BPMN
import BpmnViewer from "bpmn-js";
// BPMN

// Utils
import { downloadAsBpmnFile } from "../../../Utils/DownloadUtils/downloadAsBpmnFile";
// Utils

// Constants
import { downloadProcessNamePreFix } from "../../../Constants/constants";
// Constants

// Icons
import { AiOutlineDownload } from "react-icons/ai";
// Icons

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
// Redux

// Events
// Events

// Contexts
import {
  initialProcessHistoryContextData,
  ProcessHistoryContext,
  SetAllSteps,
  SetProcessHistoryContext,
  SetStepsPaginationData,
} from "../../../Contexts/ProcessHistoryContext/ProcessHistoryContext";
// Contexts

// Events
import { diagramClickEvent } from "../../../App";
import { getAsyncProcessActivityStepsWithoutPendingWithSameDataFlow } from "../../../Features/ProcessesSlice/ProcessInstanceHistorySlice/processInstanceHistorySlice";
import { useParams } from "react-router";
import { I_ProcessActivitySteps } from "../../../Models/interfaces";
import { customizedToast } from "../../../Utils/CustomizedToast/customizedToast";
import { getStepsOfSingleActivityBySchemaIdService } from "../../../Services/Processes/ProcessHistory/get/getStepsOfSingleActivityBySchemaIdService";
// Events

type BPMN_Props = {
  xmlData: string;
  currentStateIdContainer?: string;
  className?: string;
  colorControlling: {
    bgDoneColor: string;
    setBgDoneColor: React.Dispatch<React.SetStateAction<string>>;
    textDoneColor: string;
    setTextDoneColor: React.Dispatch<React.SetStateAction<string>>;
  };
  getSelectedStep: (stepId: string, extraData?: any) => void;
  selectedElements: {
    elementId: string;
  };
  getNewSchema: (stepId: string) => void;
};

const BPMN_VIEWER: React.FunctionComponent<BPMN_Props> = ({
  xmlData,
  currentStateIdContainer,
  className,
  colorControlling: { bgDoneColor, textDoneColor },
  getSelectedStep,
  selectedElements,
}) => {
  const { instanceId } = useParams();

  const [viewerReference, setViewerReference] = useState<any>();
  const [isPending, setIsPending] = useState<boolean>(true);
  console.log(isPending);
  const [zoomLevel, setZoomLevel] = useState(0.7);

  const canvasRef = useRef<any>(null);

  const setProcessHistoryData = useContext(SetProcessHistoryContext);

  const dispatch = useAppDispatch();

  const activityContent = useAppSelector(
    (state) =>
      state.processInstanceHistorySlice.activityInformation.data.content
  );

  // const { _stepNumber } = useContext(ProcessHistoryContext);

  const instanceData = useAppSelector(
    (state) => state.processes.processInstanceData.processInstanceSchema
  );

  const { isTabsOpen } = useContext(ProcessHistoryContext);

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);

  const setAllSteps = useContext(SetAllSteps);

  const setProcessHistoryContext = useContext(SetProcessHistoryContext);

  const setStepsPaginationData = useContext(SetStepsPaginationData);

  const fetchStepDataWithoutPending = useCallback(
    (stepNumber: number | string, showAlert?: boolean) => {
      dispatch(
        getAsyncProcessActivityStepsWithoutPendingWithSameDataFlow({
          userToken,
          _data: {
            processInstanceId: instanceId as string,
            size: 1,
            stepNumber: stepNumber.toString(),
            onDone: (__data: I_ProcessActivitySteps) => {
              setAllSteps([]);
              setProcessHistoryContext(initialProcessHistoryContextData);
              const newSteps = [
                ...(__data.stepsBefore?.steps || []),
                ...(__data.currentStep?.steps || []),
                ...(__data.stepsAfter?.steps || []),
              ].sort((a, b) => a.stepNumber - b.stepNumber);
              setAllSteps((prevSteps) => {
                const data = [...new Set([...prevSteps, ...newSteps])].sort(
                  (a, b) => a.stepNumber - b.stepNumber
                );
                setProcessHistoryContext((_prevState) => ({
                  ..._prevState,
                  _stepNumber:
                    __data.stepsBefore?.steps[0]?.stepNumber ||
                    __data.currentStep?.steps[0]?.stepNumber ||
                    _prevState._stepNumber,
                  allSteps: data,
                }));

                return data;
              });
              getSelectedStep(
                __data.stepsBefore.steps[0]?.activities[0]?.schemaId ||
                  __data.currentStep.steps[0]?.activities[0]?.schemaId
              );
              setStepsPaginationData((prevState) => ({
                ...prevState,
                stepNumber:
                  __data.stepsBefore?.steps[0]?.stepNumber ||
                  __data.currentStep?.steps[0]?.stepNumber ||
                  (stepNumber as number),
              }));
            },
            onBeforeRequest: () => {
              if (!showAlert) return;
              customizedToast("در حال دریافت اطلاعات", "info");
            },
          },
        })
      );
    },
    [
      dispatch,
      userToken,
      instanceId,
      getSelectedStep,
      setProcessHistoryContext,
      setAllSteps,
      setStepsPaginationData,
    ]
  );

  useEffect(() => {
    if (viewerReference) {
      const canvas = viewerReference.get("canvas");
      canvasRef.current = canvas;

      const container: any = document.getElementById("bpmn-container");

      let isDragging = false;
      let startPos = { x: 0, y: 0 };
      let accumulatedDisplacement = { x: 0, y: 0 };
      let oldViewbox: any;

      const handleMouseDown = (event: any) => {
        isDragging = true;
        startPos = { x: event.clientX, y: event.clientY };
        oldViewbox = canvas.viewbox();
      };

      const handleMouseMove = (event: any) => {
        if (!isDragging) return;

        const dx = event.clientX - startPos.x;
        const dy = event.clientY - startPos.y;

        accumulatedDisplacement.x += dx;
        accumulatedDisplacement.y += dy;

        const newX = oldViewbox.x - accumulatedDisplacement.x;
        const newY = oldViewbox.y - accumulatedDisplacement.y;

        canvas.viewbox({ ...oldViewbox, x: newX, y: newY });

        startPos = { x: event.clientX, y: event.clientY };
      };

      const handleMouseUp = () => {
        isDragging = false;
        accumulatedDisplacement = { x: 0, y: 0 };
      };

      container.addEventListener("mousedown", handleMouseDown);
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);

      container.addEventListener("pointerdown", handleMouseDown);
      window.addEventListener("pointermove", handleMouseMove);
      window.addEventListener("pointerup", handleMouseUp);
      return () => {
        container.removeEventListener("mousedown", handleMouseDown);
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleMouseUp);

        container.removeEventListener("pointerdown", handleMouseDown);
        window.removeEventListener("pointermove", handleMouseMove);
        window.removeEventListener("pointerup", handleMouseUp);
      };
    }
  }, [viewerReference]);
  //
  useEffect(() => {
    const viewer = viewerReference;
    if (!viewer) return;
    if (
      typeof selectedElements.elementId === "string" &&
      selectedElements.elementId !== ""
    ) {
      var selection = viewer.get("selection");
      var elementRegistry = viewer.get("elementRegistry");
      var rectElement = elementRegistry.get(selectedElements.elementId);

      if (rectElement) {
        console.clear();
        console.log(rectElement);
        if ("id" in rectElement) {
          const selectedId = rectElement["id"];
          if (
            typeof selectedId === "string" &&
            instanceData.validSchemas
              .map((_x) => _x.schemaId)
              .includes(selectedId)
          ) {
            selection.select(rectElement);
          } else {
            console.log(selection);
            selection.select(null);
          }
        }
        // zoom to selected Rect
        // viewer.get("canvas").zoom("fit-viewport", rectElement);
        const currentZoomLevel = viewer.get("canvas").zoom();
        setZoomLevel(currentZoomLevel);
        // zoom to selected Rect
      }
    }
  }, [selectedElements.elementId, viewerReference, instanceData.validSchemas]);
  //
  useEffect(() => {
    if (xmlData === "") return;
    const container = document.getElementById("bpmn-container");
    const viewer = new BpmnViewer({ container });
    setProcessHistoryData((prevState) => ({
      ...prevState,
      bpmnReference: viewer,
    }));
    const currentProcessState = viewer.getDefinitions();
    console.log(currentProcessState);
    console.log("viewer D", viewer._definitions);
    console.log("viewer", viewer);
    const eventBus = viewer.get("eventBus");

    // function applyCustomStylesToAll(viewer: any) {
    //   const elementRegistry = viewer.get("elementRegistry");

    //   elementRegistry.forEach(function (element: any) {
    //     const gfx = viewer.get("elementRegistry").getGraphics(element.id);
    //     alert(JSON.stringify(gfx));
    //   });
    // }

    eventBus.on("element.click", (event: any) => {
      const element = event.element;
      // dispatchEvent(processHistoryResetEvent);
      console.log(event?.element?.id);
      if (
        String(event?.element?.id).toLowerCase()?.includes("act") &&
        instanceData.validSchemas
          .map((_x) => _x.schemaId)
          .includes(event?.element?.id)
      ) {
        getSelectedStep(event?.element?.id, event);
        setProcessHistoryData((prevState) => ({
          ...prevState,
          selectedDiagramId: event?.element?.id,
          clickedElementId: event?.element?.id,
        }));

        const fetcher = async () => {
          const {
            data: {
              result: { content },
            },
          } = await getStepsOfSingleActivityBySchemaIdService({
            userToken,
            _data: {
              activitySchemaId: String(event?.element?.id),
              page: 1,
              processInstanceId: instanceId as string,
              size: 1,
            },
          });
          fetchStepDataWithoutPending(content[0].stepNumber + 1);
        };

        fetcher();

        setTimeout(() => {
          dispatchEvent(diagramClickEvent);
        }, 500);

        // setTimeout(() => {
        //   dispatchEvent(resetProcessTable);
        //   setTimeout(() => {
        //     dispatchEvent(processItemClicked);
        //   }, 100);
        // }, 500);
      }
      if (element.id === "bpmn:Task") {
        alert("x");
        console.log("Task clicked:", element.id);
      }
    });

    // viewer.on("element.hover", function (event: any) {
    //   const gfx = event.gfx;
    //   console.clear();
    //   console.log(event);
    //   if ("element" in event) {
    //     if ("id" in event.element) {
    //       if (
    //         instanceData.validSchemas
    //           .map((_x) => _x.schemaId)
    //           .includes(event.element.id)
    //       ) {
    //         gfx.classList.add("hover-effect");
    //       }
    //     }
    //   }
    // });

    // viewer.on("element.out", function (event: any) {
    //   const gfx = event.gfx;
    //   gfx.classList.remove("hover-effect");
    // });

    setViewerReference(viewer);
    viewer.get("canvas").zoom("fit-viewport", "auto");
    const currentZoomLevel = viewer.get("canvas").zoom();
    setZoomLevel(currentZoomLevel);
    setIsPending(false);
    setTimeout(() => {
      const els = document.querySelectorAll("text > tspan");
      console.log(els);
      if (els.length === 0) return;
      els.forEach((item) => {
        const currX = item.getAttribute("x");
        item.setAttribute("x", String(Number(currX) / 2));
      });
    }, 100);

    async function loadXmlData() {
      try {
        const result = await viewer.importXML(xmlData);
        const { warnings } = result;
        console.log(warnings);
      } catch (err: any) {
        console.log(err.message, err.warnings);
      }
    }

    loadXmlData().then(() => {
      // applyCustomStylesToAll(viewer);
    });

    // for reset the size of diagram ;)
    console.log(isTabsOpen);
    // for reset the size of diagram ;)

    return () => {
      viewer.destroy();
    };
  }, [
    xmlData,
    getSelectedStep,
    setProcessHistoryData,
    instanceData,
    isTabsOpen,
    instanceId,
    userToken,
    fetchStepDataWithoutPending,
  ]);
  //
  useEffect(() => {
    const viewer = viewerReference;
    if (!viewer) return;
    const timeout = setTimeout(() => {
      viewer.get("canvas").zoom("fit-viewport", "auto");
      const currentZoomLevel = viewer.get("canvas").zoom();
      setZoomLevel(currentZoomLevel);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [isTabsOpen, viewerReference]);
  //
  useEffect(() => {
    const timeOut = setTimeout(() => {
      // const viewer = viewerReference;
      // if (!viewer) return;
      // console.clear();
      // console.log(_stepNumber);
      const ids = activityContent.map((item) => item.schemaId);

      // reset
      const els = document.querySelectorAll(`[data-element-id]`);
      els.forEach((_item) => {
        _item.classList.remove("selectedInDiagram");
      });
      // reset

      // attach classes
      ids.forEach((item) => {
        const el = document.querySelector(`[data-element-id=${item}]`);
        if (!el) return;
        el.classList.add("selectedInDiagram");
      });
      // attach classes

      // console.log(ids);

      // const elementRegistry = viewer.get("elementRegistry");
      // const selection = viewer.get("selection");

      // const elementsToSelect = ids.map((item) => elementRegistry.get(item));
      // console.log(elementsToSelect);

      // const canvas = viewerReference.get("canvas");

      // elementRegistry.forEach((element: any) => {
      //   const graphics = canvas.getGraphics(element);
      //   if (graphics) {
      //     graphics.setAttribute(
      //       "class",
      //       graphics
      //         .getAttribute("class")
      //         .split(" ")
      //         .filter((i: string) => i !== "selectedInDiagram")
      //         .join(" ")
      //     ); // Reset class
      //   }
      // });

      // elementsToSelect.forEach((item) => {
      //   const graphics = canvas.getGraphics(item);
      //   if (graphics) {
      //     graphics.setAttribute(
      //       "class",
      //       graphics
      //         .getAttribute("class")
      //         .split(" ")
      //         .push("selectedInDiagram")
      //         .join(" ")
      //     );
      //     graphics.addEventListener("click", (event: any) => {
      //       event.stopPropagation();
      //       console.log(`Clicked on element: ${item.id}`);
      //     });
      //   }
      // });

      // selection.select(elementsToSelect);
    }, 1);

    return () => {
      clearTimeout(timeOut);
    };
  }, [
    activityContent,
    // viewerReference,
    // _stepNumber
  ]);

  const handleZoom = useCallback(
    (factor: number) => {
      if (viewerReference) {
        const newZoomLevel = zoomLevel + factor;
        setZoomLevel(newZoomLevel);

        const canvas = viewerReference.get("canvas");
        const oldViewbox = canvas.viewbox();

        // Center coordinates before zoom
        const zoomCenterX = oldViewbox.x + oldViewbox.width / 2;
        const zoomCenterY = oldViewbox.y + oldViewbox.height / 2;

        // Zoom with specified center point
        canvas.zoom(newZoomLevel, { x: zoomCenterX, y: zoomCenterY });

        // Fetch the new viewbox to adjust position after zoom
        const newViewbox = canvas.viewbox();
        const adjustedX = zoomCenterX - newViewbox.width / 2;
        const adjustedY = zoomCenterY - newViewbox.height / 2;

        // Reapply viewbox centered on the zoom point
        canvas.viewbox({
          x: adjustedX,
          y: adjustedY,
          width: newViewbox.width,
          height: newViewbox.height,
        });
      }
    },
    [viewerReference, zoomLevel]
  );

  const handleZoomIn = () => {
    handleZoom(0.03);
  };

  const handleZoomOut = () => {
    handleZoom(-0.03);
  };

  return (
    <>
      <div
        className={` d-flex flex-row align-items-center align-self-end ${className}`}
      >
        <button
          className="operationEvent submitBtn"
          data-tooltip-id="my-tooltip"
          data-tooltip-content="BPMN دانلود فایل "
          onClick={() => {
            downloadAsBpmnFile(
              [xmlData],
              `${downloadProcessNamePreFix}${currentStateIdContainer}`
            );
          }}
          style={{
            height: "max-content !important",
          }}
        >
          <AiOutlineDownload />
        </button>
        <button onClick={handleZoomIn} className={` operationEvent  zoomInBtn`}>
          <span className="icon-search-zoom-in"></span>
        </button>
        <button
          onClick={handleZoomOut}
          className={` operationEvent zoomOutBtn`}
        >
          <span className="icon-search-zoom-out"></span>
        </button>
      </div>
      <style>
        {`

        ${
          // CSS FOR BPMN DIAGRAM
          ""
          // CSS FOR BPMN DIAGRAM
        }
        .hover-effect .djs-visual > :nth-child(1) {
            stroke: yellow !important;
            stroke-width: 8px !important;
        }

        .hover-effect .djs-visual > :nth-child(2) {
            fill: rgba(51, 51, 51, 1) !important;
        }

        .hover-reset .djs-visual > :nth-child(1) {
            stroke: none !important;
            stroke-width: 8px !important;
        }

        .hover-reset .djs-visual > :nth-child(2) {
            fill: none !important;
        }

        ${
          // CSS FOR BPMN DIAGRAM
          ""
          // CSS FOR BPMN DIAGRAM
        }
  
      [data-element-id="${currentStateIdContainer}"] g rect {
        fill: ${bgDoneColor} !important;
      }
      [data-element-id="${currentStateIdContainer}"] tspan {
        fill: ${textDoneColor} !important;
      }
      [id="${currentStateIdContainer}"] g rect {
        stroke: teal !important;
        fill: ${bgDoneColor} !important;
      }
      [id="${currentStateIdContainer}"] tspan {
      }
      #${currentStateIdContainer} g rect,
      #${currentStateIdContainer} g > rect {
        fill: ${bgDoneColor} !important;
      }
      #${currentStateIdContainer} tspan {
        fill: ${textDoneColor} !important;
      }`}
      </style>

      <style>
        {`
          ${instanceData.validSchemas
            .map((item) => item.schemaId)
            .map(
              (item) => `
            [data-element-id="${item}"] g rect {
                 filter: drop-shadow(0px 0px 10px green);
            }

             [data-element-id="${item}"] > g > rect {
                 filter: drop-shadow(0px 0px 10px green);
            }
            
             
            `
            )}

            .selected {
            
            
            }
          
          `}
      </style>
      <div
        id="bpmn-container"
        style={{
          height: !isTabsOpen ? "500px" : "300px",
          width: "100%",
          cursor: "grab",
        }}
        // onClick={() => {
        //   dispatchEvent(processHistoryResetEvent);
        // }}
      ></div>
    </>
  );
};

export default BPMN_VIEWER;
