// React
import React, { useContext, useMemo, useState } from "react";
// React

// CSS
import styles from "./HistoryTabItem.module.css";
// CSS

// Models
import { I_HistoryDetail } from "../../../../../Models/interfaces";
// Models

// Components
import TaskInformation, {
  withoutInformation,
} from "./TaskInformation/TaskInformation";
import WorkFlow from "./WorkFlow/WorkFlow";
import ProcessData from "./ProcessData/ProcessData";
// Components

// Contexts
import { ProcessHistoryContext } from "../../../../../Contexts/ProcessHistoryContext/ProcessHistoryContext";
import RowItem from "./RowItem/RowItem";
import ProcessSelectModal from "./ProcessData/ProcessSelectModal/ProcessSelectModal";
// Contexts

type HistoryTabItemProps = {
  data: I_HistoryDetail;
};

export type ProcessSelectModalData = {
  isModalInScreen: boolean;
  processId: string;
};

const HistoryTabItem: React.FunctionComponent<HistoryTabItemProps> = ({
  data,
}) => {
  const processHistoryData = useContext(ProcessHistoryContext);

  const [processSelectModalData, setProcessSelectModalData] =
    useState<ProcessSelectModalData>({
      isModalInScreen: false,
      processId: "",
    });

  const _data: {
    title: string | number;
    value: string | number | React.ReactElement;
  }[] = useMemo(
    () =>
      processHistoryData.selectedHistoryType === "callActivity"
        ? [
            {
              title: "",
              value: (
                <button
                  className="w-100 submitBtn p-2 rounded-2"
                  onClick={() => {
                    setProcessSelectModalData({
                      isModalInScreen: true,
                      processId: data?.callActivityDetail?.instanceId || "",
                    });
                  }}
                >
                  ورود به زیر فرآیند
                </button>
              ),
            },
            {
              title: "نام تاریخچه: ",
              value:
                data?.callActivityDetail?.process?.processId ||
                withoutInformation,
            },
            {
              title: "نوع فعالیت: ",
              value:
                data?.callActivityDetail?.process?.accessType.title ||
                withoutInformation,
            },
            {
              title: "وضعیت دسترسی",
              value:
                data?.callActivityDetail?.process?.accessType?.title ||
                withoutInformation,
            },
          ]
        : [
            {
              title: "نام وظیفه: ",
              value: data?.taskDetail?.taskTitle || withoutInformation,
            },
          ],
    [data, processHistoryData]
  );

  return (
    <div
      className={`${styles.historyTabItemContainer} w-100 d-flex flex-column align-items-center`}
    >
      {processSelectModalData.isModalInScreen && (
        <ProcessSelectModal
          modalData={processSelectModalData}
          setModalData={setProcessSelectModalData}
        />
      )}
      {processHistoryData.selectedHistoryType === "userTask" ? (
        <TaskInformation data={data} />
      ) : null}

      {processHistoryData.selectedHistoryType === "callActivity" ? (
        <>
          <div
            className="w-100 d-flex flex-row align-items-center
         justify-content-center flex-wrap py-2
         gap-2
         "
          >
            {_data.map((item, index) => (
              <RowItem
                data={item}
                key={item.title}
                isOdd={index !== 0}
                isTitleRtl
              />
            ))}
          </div>
          <WorkFlow data={data} />
          <ProcessData data={data} />
        </>
      ) : null}
    </div>
  );
};

export default HistoryTabItem;
