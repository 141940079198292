// React
import React, { useContext, useMemo } from "react";
// React

// CSS
import styles from "./TaskInformation.module.css";
// CSS

// Models
import { I_HistoryDetail } from "../../../../../../Models/interfaces";
import Accordion from "../../../../../Accordion/Accordion";
import RowItem from "../RowItem/RowItem";
import { ProcessHistoryContext } from "../../../../../../Contexts/ProcessHistoryContext/ProcessHistoryContext";
// Models

type TaskInformationProps = {
  data: I_HistoryDetail;
};

export const withoutInformation = "فاقد اطلاعات";

const TaskInformation: React.FunctionComponent<TaskInformationProps> = ({
  data: _data,
}) => {
  const processHistoryData = useContext(ProcessHistoryContext);

  const data: {
    title: string | number;
    value: string | number;
  }[] = useMemo(
    () =>
      processHistoryData.selectedHistoryType === "userTask"
        ? [
            {
              title: "نام تسک",
              value: _data?.taskDetail?.taskTitle || withoutInformation,
            },
            {
              title: "مهلت انجام",
              value:
                _data?.taskDetail?.dueDate?.jalaliDate || withoutInformation,
            },
            {
              title: "آخرین بروزرسانی",
              value:
                _data?.taskDetail?.followUpDate?.jalaliDate ||
                withoutInformation,
            },
            {
              title: "الویت تسک",
              value:
                _data?.taskDetail?.priorityType?.title || withoutInformation,
            },
            {
              title: "زمان شروع تسک",
              value:
                _data?.taskDetail?.startTime?.jalaliDate || withoutInformation,
            },
            {
              title: "زمان پایان تسک",
              value:
                _data?.taskDetail?.endTime?.jalaliDate || withoutInformation,
            },
            {
              title: "تعداد افرادی که روی این تسک کارکردن",
              value: _data?.taskDetail.candidateUserCount || withoutInformation,
            },
            {
              title: "تعداد گروه هایی که روی این تسک کارکردن",
              value:
                _data?.taskDetail.candidateGroupCount || withoutInformation,
            },
            {
              title: " : کاربر تایید کننده",
              value:
                typeof _data?.taskDetail?.submitterUser?.firstname ===
                  "undefined" ||
                typeof _data?.taskDetail?.submitterUser?.lastname ===
                  "undefined"
                  ? withoutInformation
                  : `${_data?.taskDetail?.submitterUser?.firstname} ` +
                      `${_data?.taskDetail?.submitterUser?.lastname}` ||
                    withoutInformation,
            },
          ]
        : [],
    [_data, processHistoryData]
  );

  return (
    <div className={`${styles.taskInformationContainer} w-100`}>
      <Accordion
        topName="اطلاعات تسک"
        containerClassName="w-100 d-flex flex-column align-items-center mt-4"
      >
        <div
          className="w-100 d-flex flex-row align-items-center
         justify-content-center flex-wrap py-2
         gap-2
         "
        >
          {data.map((item, index) => (
            <RowItem data={item} key={item.title} isOdd />
          ))}
        </div>
      </Accordion>
    </div>
  );
};

export default TaskInformation;
