// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./AccessibleProcessTab.module.css";
// CSS

// Components
// Components

// Redux
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import {
  getAsyncProcessesList,
  processesSlice,
} from "../../../../Features/ProcessesSlice/ProcessesSlice";
import { getAsyncAccessibleUserProcesses } from "../../../../Features/SingleUserAccessibleProcessSlice/SingleUserAccessibleProcessSlice";
import { editAsyncAccessibleUserProcess } from "../../../../Features/AccessibleUsersListSlice/AccessibleUsersListSlice";
// Redux

// Modules
import { useParams } from "react-router-dom";
// Modules

// Utils
import Swal from "sweetalert2";
import Pagination from "../../../../Utils/Pagination/Pagination";
import { Spinner } from "reactstrap";
// Utils

// Contexts
import { UserGroupsContext } from "../../../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsFullRenderdContext } from "../../../../Contexts/UserGroupsContext/IsFullRenderdContext";
import { IsNightModeOnContext } from "../../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Icons
import { MdDone } from "react-icons/md";
import { BsDashLg } from "react-icons/bs";
import { permitionCheckerWithoutToast } from "../../../../Modules/permitionCheckerWithoutToast";
// Icons

const AccessibleProcessTab = () => {
  const params = useParams();

  const { selectedTenant } = useAppSelector((state) => state.tenants);

  const [formsData, setFormsData] = useState<{
    page: number;
    size: number;
    search: string;
  }>({
    page: 1,
    size: 10,
    search: "",
  });

  const currLang = useAppSelector((state) => state.currLanguage.language);

  const dispatch = useAppDispatch();
  const singleUserAccessibleProcess = useAppSelector(
    (state) => state.singleUserAccessibleProcess
  );
  const allGroups = useContext(UserGroupsContext);
  const isNightOn = useContext(IsNightModeOnContext);

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const processData = useAppSelector((state) => state.processes);

  const isFullRenderd = useContext(IsFullRenderdContext);

  useEffect(() => {
    if (
      (permitionCheckerWithoutToast(
        allGroups,
        ["Tenant_Full_Manager", "Tenant_Basic_Manager"],
        isFullRenderd
      ) === false ||
        permitionCheckerWithoutToast(
          allGroups,
          ["Process_Full_Manager", "Process_Staff_Editor"],
          isFullRenderd
        ) === false) &&
      permitionCheckerWithoutToast(
        allGroups,
        ["Full_Manager", "User_Editor"],
        isFullRenderd
      ) === false
    )
      return;
    const timeOut = setTimeout(() => {
      dispatch(
        getAsyncAccessibleUserProcesses({
          userName: String(params.userId),
          userToken,
          search: formsData.search,
          page: formsData.page,
          size: formsData.size,
        })
      );
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [allGroups, dispatch, params.userId, userToken, isFullRenderd, formsData]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      dispatch(
        getAsyncProcessesList({
          _data: {
            page: formsData.page,
            search: formsData.search,
            size: formsData.size,
            startable: false,
            tenant: selectedTenant.tenantId,
          },
          userToken,
        })
      );
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [dispatch, selectedTenant, userToken, formsData]);

  useEffect(() => {
    return () => {
      dispatch(processesSlice.actions.resetProcessListPending());
    };
  }, [dispatch]);

  return (
    <div
      className={`w-100 d-flex flex-column align-items-center ${
        styles.processTabContainer
      } ${isNightOn ? "nightBg1" : ""}`}
    >
      <p
        style={{
          width: "100%",
          textAlign: "right",
          color: "#111D64",
          fontWeight: "bold",
          fontSize: "20px",
        }}
      >
        ویرایش کاربر
      </p>
      <p
        style={{
          width: "100%",
          textAlign: "right",
          color: "#8392AB",
          fontSize: "16px",
        }}
      >
        مشاهده فرآیند ها
      </p>
      <div className="w-100 d-flex flex-row-reverse align-items-center justify-content-between my-2">
        <p
          style={{
            textAlign: "right",
            color: "#111D64",
            fontWeight: "bold",
          }}
        >
          جستجو در فرآیند ها
        </p>
        <input
          type="text"
          onChange={(e) => {
            setFormsData((prevState) => ({
              ...prevState,
              search: e.target.value,
            }));
          }}
          style={{
            border: "1px solid #E9ECEF",
            direction: "rtl",
            padding: "0 8px",
            borderRadius: "8px",
            minHeight: "32px",
          }}
          placeholder="جست و جو کنید ..."
        />
      </div>

      {singleUserAccessibleProcess.isGettingUserProcessPending ||
      processData.isGettingProcessesPending ? (
        <div className="w-100 d-flex flex-column align-items-center justify-content-center mt-2">
          <span>در حال بارگیری</span>
          <Spinner className="mt-2" />
        </div>
      ) : (
        <>
          {processData.content.length === 0 ? (
            <>موردی وجود ندارد</>
          ) : (
            <>
              <div
                className={`${styles.myTable} ${
                  isNightOn ? "nightBg2 pcNight" : ""
                } mt-5 w-100 d-flex flex-column align-items-center align-items-center`}
              >
                <div
                  className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between align-items-center`}
                >
                  <p className={`${styles.operationTab}`}>عملیات</p>
                  <p className={`${styles.hasStartingForm}`}>فرم اولیه</p>
                  <p className={`${styles.startable}`}>قابل شروع</p>
                  <p className={`${styles.processKey}`}>کلید یکتا</p>
                  <p className={`${styles.processName}`}>نام فرآیند</p>
                </div>
                <div
                  className={`${styles.tableBody} w-100 d-flex align-items-center flex-column`}
                >
                  {processData.content.map(
                    ({
                      deploymentId,
                      hasStartingForm,
                      processFileName,
                      processKey,
                      processName,
                      startable,
                    }) => (
                      <div
                        key={deploymentId}
                        className={`${styles.tableData} w-100 d-flex align-items-center justify-content-between align-items-center`}
                      >
                        <div className={`${styles.operationTab}`}>
                          <p
                            className={`operationEvent ${
                              !singleUserAccessibleProcess.content
                                .map((item) => item.processKey)
                                .includes(processKey)
                                ? "submitBtn"
                                : "deleteBtn"
                            }`}
                            onClick={() => {
                              if (
                                !singleUserAccessibleProcess.content
                                  .map((item) => item.processKey)
                                  .includes(processKey)
                              ) {
                                Swal.fire({
                                  icon: "question",
                                  title:
                                    "دسترسی فرآیند به این کاربر افزوده شود ؟",
                                  text: "آیا اطمینان دارید ؟",
                                  showCancelButton: true,
                                  cancelButtonText: "لغو",
                                  confirmButtonText: "تایید",
                                  customClass: isNightOn ? "swalNight" : "",
                                }).then(({ isConfirmed }) => {
                                  if (!isConfirmed) return;
                                  dispatch(
                                    editAsyncAccessibleUserProcess({
                                      userName: String(params.userId),
                                      _data: {
                                        processKeysToAdd: [
                                          {
                                            processKey,
                                          },
                                        ],
                                        processKeysToDelete: [],
                                      },
                                      userToken,
                                    })
                                  ).then(() => {
                                    dispatch(
                                      getAsyncAccessibleUserProcesses({
                                        userName: String(params.userId),
                                        userToken,
                                        search: formsData.search,
                                        page: formsData.page,
                                        size: formsData.size,
                                      })
                                    );
                                  });
                                });
                                return;
                              }

                              Swal.fire({
                                icon: "question",
                                title: "دسترسی فرآیند از این کاربر سلب شود ؟",
                                text: "آیا اطمینان دارید ؟",
                                showCancelButton: true,
                                cancelButtonText: "لغو",
                                confirmButtonText: "تایید",
                                customClass: isNightOn ? "swalNight" : "",
                              }).then(({ isConfirmed }) => {
                                if (!isConfirmed) return;
                                dispatch(
                                  editAsyncAccessibleUserProcess({
                                    userName: String(params.userId),
                                    _data: {
                                      processKeysToAdd: [],
                                      processKeysToDelete: [
                                        {
                                          processKey,
                                        },
                                      ],
                                    },
                                    userToken,
                                  })
                                ).then(() => {
                                  dispatch(
                                    getAsyncAccessibleUserProcesses({
                                      userName: String(params.userId),
                                      userToken,
                                      search: formsData.search,
                                      page: formsData.page,
                                      size: formsData.size,
                                    })
                                  );
                                });
                              });
                            }}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={
                              !singleUserAccessibleProcess.content
                                .map((item) => item.processKey)
                                .includes(processKey)
                                ? currLang.add
                                : currLang.delete
                            }
                          >
                            {!singleUserAccessibleProcess.content
                              .map((item) => item.processKey)
                              .includes(processKey) ? (
                              <span className="icon-Plus"></span>
                            ) : (
                              <span className="icon-trash"></span>
                            )}
                          </p>
                        </div>
                        <p className={`${styles.hasStartingForm}`}>
                          {hasStartingForm ? (
                            <MdDone
                              style={{
                                fontSize: "2rem",
                              }}
                            />
                          ) : (
                            <BsDashLg
                              style={{
                                fontSize: "2rem",
                              }}
                            />
                          )}
                        </p>
                        <p className={`${styles.startable}`}>
                          {startable ? (
                            <MdDone
                              style={{
                                fontSize: "2rem",
                              }}
                            />
                          ) : (
                            <BsDashLg
                              style={{
                                fontSize: "2rem",
                              }}
                            />
                          )}
                        </p>
                        <p className={`${styles.processKey}`}>{processKey}</p>
                        <p className={`${styles.processName}`}>{processName}</p>
                      </div>
                    )
                  )}
                </div>
                <div className={`${styles.tableFooter}`}></div>
              </div>
            </>
          )}
        </>
      )}

      <Pagination
        dataForPagination={{
          currPage: formsData.page,
          size: formsData.size,
          totalPages: singleUserAccessibleProcess.totalPages,
        }}
        onPageNumberChange={(page) => {
          setFormsData((prevState) => ({ ...prevState, page }));
        }}
        onPageNumbersClick={(page) => {
          setFormsData((prevState) => ({ ...prevState, page }));
        }}
        onPageSizeChange={(size) => {
          setFormsData((prevState) => ({ ...prevState, size }));
        }}
        search={formsData.search}
      />
    </div>
  );
};

export default AccessibleProcessTab;
